import React, { useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import ConfettiExplosion from "react-confetti-explosion";
import Panier from "../../../assets/images/appImages/paymentError.png";
import { NavLink } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyOrderByTransactionIDQuery } from "../../../utils/api/order/order.api";
import { Order } from "../../../utils/api/order/order.type";
import {
  formatCurrency,
  getImage,
  useLocationState, 
} from "../../../utils/Utils";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { useAppDispatch } from "../../../redux/hook";
import { removeCart } from "../../../redux/slice/useCartSlice";
import { orderType } from "./Commande/CommandeItem";
import { getVendeur } from "../../HomePage/HomePage";

// import { BsFillExclamationOctagonFill } from "react-icons/bs";
function OrderItem() {
  const Vendeur = getVendeur();
  let [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [findById, { data, isError, isLoading, isSuccess }] =
    useLazyOrderByTransactionIDQuery();
  const navigate = useNavigate();
  const itemState = useLocationState<Order | undefined>(undefined);
  const [item, setItem] = useState(itemState);

  useEffect(() => {
    if (searchParams.get("transactionId")) {
      findById(searchParams.get("transactionId") as string);
    }
    if (itemState) {
      dispatch(removeCart());
    }
    // console.log("params", searchParams.get("transactionId"));
  }, [searchParams, itemState]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError, itemState]);
  useEffect(() => {
    if (data) {
      // console.log("data", data);
      setItem(data);
      dispatch(removeCart());
    }
  }, [data]);
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <div className="container-easy-market">
          <div className="content-title-register-page ">
            <h2 className="title-register-page">Details de la commande</h2>
          </div>
          <div className="row row-register-page mb-5 content-col-right-register-page">
            {!!isSuccess ||
              (itemState && (
                <div className="px-4">
                  <div className="alert alert-success alert-dismissible fade show">
                    <ConfettiExplosion
                      particleCount={250}
                      duration={2500}
                      width={2000}
                    />
                    <AiFillCheckCircle />
                    <strong className="mx-2">Succès!</strong>
                    Votre commande a été envoyée avec succès.
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                    ></button>
                  </div>
                </div>
              ))}

            {!isLoading &&
              (isError || searchParams.get("status") === "error") && (
                <div className="error-payment text-center">
                  <div className="content-img-panier-vide pb-4 text-center">
                    <img
                      loading="lazy"
                      src={Panier}
                      alt="Panier"
                      className="img-panier-vide w-30"
                    />
                  </div>

                  <h3 className="titre-panier-vide text-danger">
                    Paiement échoué!
                  </h3>

                  <p className="text-panier-vide">
                    Un problème est survenu lors du paiement de votre commande.
                  </p>
                  {/* <div className="alert alert-danger alert-dismissible d-flex align-items-center fade show">
                  <BsFillExclamationOctagonFill />
                  <strong className="mx-2">Oops!</strong> Un problème est
                  survenu lors du paiement de votre commande.
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                  ></button>
                </div> */}
                  <div className="content-btn-action-panier pt-4 text-center">
                    <NavLink to="/achats" className="btn btn-theme ">
                      Commander à nouveau
                    </NavLink>
                  </div>
                </div>
              )}

            {!!isLoading && (
              <div className="px-4">
                <div className="text-center">
                  <span
                    className="spinner-border spinner-border-sm me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Chargement...</span>
                </div>
              </div>
            )}
            {!isLoading && !!item && (
              <div className="content-detail-commande-page mt-3 mb-3">
                <div className="card-detail-commande-item mb-3">
                  {/* <div className="row row-detail-commande-admin">
                  <div className="col-md-6 mb-3 col-detail-commande-admin">
                    <div className="content-col-detail-commande-admin">
                      <h3 className="title-col-detail-commande-admin">
                        Produit
                      </h3>
                      <div className="content-infdetail-commande-admin">
                        <div className="content-img-commande-admin">
                          <img loading="lazy"
                            src={Product}
                            alt="Produit"
                            className="img-commande-detail-admin"
                          />
                        </div>
                        <p className="nom-commande-detail-admin mb-0">
                          Couches pampers
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 mb-3 col-detail-commande-admin">
                    <div className="content-col-detail-commande-admin">
                      <h3 className="title-col-detail-commande-admin">
                        Quantité
                      </h3>
                      <p className="nom-commande-detail-admin mb-0 pt-5">1</p>
                    </div>
                  </div>
                  <div className="col-md-2 mb-3 col-detail-commande-admin">
                    <div className="content-col-detail-commande-admin">
                      <h3 className="title-col-detail-commande-admin">Prix</h3>
                      <p className="nom-commande-detail-admin mb-0 pt-5">
                        3000
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2 mb-3 col-detail-commande-admin">
                    <div className="content-col-detail-commande-admin">
                      <h3 className="title-col-detail-commande-admin">Total</h3>
                      <p className="info-produit-sim-value mb-0 pt-5">
                        3 000
                        <span style={{ fontWeight: 300 }}>FCFA</span>
                      </p>
                    </div>
                  </div>
                </div> */}
                  {!!item &&
                    item?.produits?.map((product, i) => (
                      <div
                        className="row row-detail-commande-admin"
                        key={product?.id}
                      >
                        <div className="col-md-6 mb-3 col-detail-commande-admin">
                          <div className="content-col-detail-commande-admin">
                            {i === 0 && (
                              <h3 className="title-col-detail-commande-admin">
                                Produit
                              </h3>
                            )}

                            <div className="content-infdetail-commande-admin">
                              <div className="content-img-commande-admin">
                                <img
                                  loading="lazy"
                                  src={getImage("/")}
                                  alt={product?.nom}
                                  className="img-commande-detail-admin"
                                />
                              </div>
                              <p className="nom-commande-detail-admin mb-0">
                                {product?.nom}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 mb-3 col-detail-commande-admin">
                          <div className="content-col-detail-commande-admin">
                            {i === 0 && (
                              <h3 className="title-col-detail-commande-admin">
                                Quantité
                              </h3>
                            )}
                            <p className="nom-commande-detail-admin mb-0 pt-5">
                              {/* {product?.quantity} */}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-2 mb-3 col-detail-commande-admin">
                          <div className="content-col-detail-commande-admin">
                            {i === 0 && (
                              <h3 className="title-col-detail-commande-admin">
                                Prix
                              </h3>
                            )}
                            <p className="nom-commande-detail-admin mb-0 pt-5">
                              {formatCurrency(product?.prix,Vendeur?.devise)}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-2 mb-3 col-detail-commande-admin">
                          <div className="content-col-detail-commande-admin">
                            {i === 0 && (
                              <h3 className="title-col-detail-commande-admin">
                                Total
                              </h3>
                            )}
                            <p className="info-produit-sim-value mb-0 pt-5">
                              {formatCurrency(product?.prix,Vendeur?.devise)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 col-left-detail-commande">
                    <div className="card-detail-commande-item mb-3">
                      <div className="content-col-detail-commande-admin">
                        <h3 className="title-col-detail-commande-admin">
                          Acheteur
                        </h3>
                        <p className="nom-commande-detail-admin mb-0 pt-3">
                          {item?.user?.prenom + " " + item?.user?.nom}
                        </p>
                      </div>
                    </div>
                    <div className="card-detail-commande-item mb-3">
                      <div className="content-col-detail-commande-admin">
                        <h3 className="title-col-detail-commande-admin">
                          Adresse
                        </h3>
                        <p className="nom-commande-detail-admin mb-0 pt-3">
                          {/* {item?.adresse?.adresse} */}
                        </p>
                      </div>
                    </div>
                    <div className="card-detail-commande-item mb-3">
                      <div className="content-col-detail-commande-admin">
                        <h3 className="title-col-detail-commande-admin">
                          Mode de paiement
                        </h3>
                        <p className="nom-commande-detail-admin mb-0 pt-3">
                          {/* {item?.payment} */}
                        </p>
                      </div>
                    </div>
                    <div className="card-detail-commande-item mb-3">
                      <div className="content-col-detail-commande-admin">
                        <h3 className="title-col-detail-commande-admin">
                          Statut de la commande
                        </h3>
                        <div
                          className={`${
                            orderType?.find((el) => el?.value === item?.statut)
                              ?.class
                          } statut-commande`}
                        >
                          {item?.statut}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 col-right-detail-commande">
                    <div className="content-col-right-detail-commande">
                      <div className="info-detail-commande-item mb-3">
                        <p className="nom-commande-detail-admin mb-0">
                          Prix sous-total
                        </p>
                        <p className="title-col-detail-commande-admin mb-0">
                          {/* {formatCurrency(item?.subTotal)} */}
                        </p>
                      </div>
                      <div className="info-detail-commande-item mb-3">
                        <p className="nom-commande-detail-admin mb-0">
                          Frais de livraison :
                        </p>
                        <p className="title-col-detail-commande-admin mb-0">
                          À définir
                        </p>
                      </div>
                      <div className="info-detail-commande-item mb-3">
                        <p className="nom-commande-detail-admin mb-0">
                          TVA (+):
                        </p>
                        <p className="title-col-detail-commande-admin mb-0">
                          {/* {formatCurrency(item?.tva)} */}
                        </p>
                      </div>
                      <div className="info-detail-commande-item mb-3">
                        <p className="title-col-detail-commande-admin mb-0">
                          Total :
                        </p>
                        <p className="title-col-detail-commande-admin mb-0">
                          {formatCurrency(item?.montant_total,Vendeur?.devise)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="content-btn-action-panier pt-4 text-center">
                    <NavLink to="/achats" className="btn btn-theme ">
                      Commander à nouveau
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <FooterPage /> */}
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default OrderItem;
