import React, {useEffect,useState} from "react";
import StarRatingComponent from "react-star-rating-component";
import AvisProductModal from "./modal/AvisProductModal";
import {  useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getLabelRate } from "../../../utils/Utils";
import { useGetRateListByProductQuery } from "../../../utils/api/rate/rate.api";
import ConnexionModal from "../../shared/FrontHeader/modal/ConnexionModal";
import { NavLink , Link , useParams ,useLocation , useSearchParams  } from "react-router-dom";
import { Color } from "../../../utils/theme";
import Swal from "sweetalert2";
import moment from 'moment'

function AvisProduct({ product }: { product: any }) {
  const [ifAvisUser, setIfAvisUser] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [modalConnexion, setModalConnexion] = useState(false);
  const { user, token } = useAppSelector((s) => s?.user);

  const {
    data = {
      data: [],
      avisCount: 0,
      avreageAvis:0,
    },
    isLoading,
  } = useGetRateListByProductQuery({
    product: product?.slug,
  });

  useEffect(() => {
    if (data?.avis?.length > 0) {
    let auteurs = data?.avis?.map((item) =>(item?.auteur?.id))
    if(auteurs.includes(user?.id))
      setIfAvisUser(true)
    }
}, [data?.avis]);

const ifUserConnected = () => {
  if(ifAvisUser){
    Swal.fire({
      icon: "info",
      title: `Avis`,
      text: "Vous avez déjà donné votre avis.",
      iconColor: Color.success,
      showConfirmButton: false,
      timer: 4000,
      })
  }else{
    Swal.fire({
      icon: "success",
      title: `Connectez vous`,
      text: "Veuillez vous connecter pour donner votre avis .",
      iconColor: Color.success,
      showConfirmButton: false,
      timer: 3000,
      }).then(() => {
      setModalConnexion(true)
      });
  }
  
};

  return (
    <div className="container-product-item-page b-b-1 mb-3">
      <h3 className="title-section-product-item">Avis clients</h3>
      <div className="row mb-3">
        <div className="col-lg-3 col-md-4 col-avis-produit mb-3">
          <p className={data?.avisCount > 0 ? "title-rate-product-item mb-0" : "title-rate-product-item-grey" }>NOTE MOYENNE</p>
          <div className="content-note-product-item-rate">
            <p className={data?.avisCount > 0 ? "note-product-item-rate" : "note-product-item-rate-grey"}>
              {data?.averageAvis || 0}
              <span style={{ color: data?.avisCount > 0 ? "#65635F" : "#D4D6D5" , fontSize: 30 }}>/5</span>
            </p>
          </div>
          <div className="content-rate-product">
            <StarRatingComponent
              name="rate2"
              starCount={5}
              value={data?.averageAvis}
              starColor="#DDB012"
              emptyStarColor="#D4D6D5"
            />
          </div>
          <p className={data?.avisCount > 0 ? "text--rate-product-item" : "text--rate-product-item-grey"}>
              
            {'sur ' +(data?.avisCount || 0) +' avis'}
            
          </p>
          <p>
            <Link onClick={() => (user?.slug && !ifAvisUser) ? setShow(true) : ifUserConnected()} to="#" className="btn btn-add-avis-product">
              Donner mon avis
            </Link>
          </p>
        </div>
        <div className="col-lg-3 col-md-4 col-avis-rate-produit mb-3">
          <div className="content-col-avis-rate-produit">
            {data?.states?.map((item,i) => (
                <div className="col-avis-rate-produit-item mb-2" key={i}>
                  <p
                    className={data?.avisCount > 0 ? "note-avis-libelle mb-0" : "note-avis-libelle-grey mb-0" }
                    style={{ marginRight: item?.note === 1 ? ".5rem" : "0" }}
                  >
                    {item?.note + " étoile"+(item?.note !== 1 ? "s" : "")}
                  </p>

                  <div className="progress content-note-rate-avis">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={item?.percent}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: item?.percent+`%` }}
                    />
                  </div>

                  <p className={data?.avisCount > 0 ? "note-avis-libelle mb-0" : "note-avis-libelle-grey mb-0" }>{item?.count}</p>
                </div>
              ))} 
          </div>
        </div>
      </div>
      {/* <div className="row py-4 b-b-1 mb-3">
        <h3 className="solicitation-title">Évaluer ce produit</h3>
        <div className="solicitation-subtitle">
          Partagez votre opinion avec les autres clients
        </div>
        <div className="btn-solicitation-container">
          <button
            className="btn btn-solicitation btn-theme-outline"
            onClick={() => user?.slug ? setShow(true) : ifUserConnected()}
          >
            Donner mon avis client
          </button>
        </div>
        <AvisProductModal show={show} setShow={setShow} product={product} />
      </div> */}
      <div className="row py-4">
        <div className="col-lg-10 col-md-12 col-table-rate mb-3">
          {!isLoading &&
            (data?.avis?.length
              ? data?.avis?.map((item, i) => (
                  <div className="row b-1-color pt-4">
                    <div className="col-md-3 mb-3">
                      <div className="content-col-table-rate">
                        {i === 0 && 
                        <p className="title-table-rate">AUTEUR</p>
                        } 

                        <div className="content-text-value-table-rate">
                          <p className="text-value-table-rate mb-0">
                            {item?.annonyme
                              ? "Anonyme"
                              : item?.auteur?.prenom + " " + item?.auteur?.nom}
                          </p>
                          <p className="text-libelle-table-rate mb-0">
                            {moment(item?.created_at).format("DD MMMM YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="content-col-table-rate">
                        {i === 0 &&
                         <p className="title-table-rate">NOTES</p>
                        } 
                        <div className="content-text-value-table-rate">
                          <p className="text-value-table-rate mb-0">
                            {item?.note}/5
                          </p>
                          <div className="content-rate-product">
                            <StarRatingComponent
                              name="rate2"
                              starCount={5}
                              value={item?.note}
                              starColor="#DDB012"
                              emptyStarColor="#D4D6D5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="content-col-table-rate">
                        {i === 0 && 
                        <p className="title-table-rate">MOTIF</p>
                        }
                        <div className="content-text-value-table-rate">
                          <p className="text-libelle-table-rate mb-0">
                            {item?.motif || ""}
                          </p>
                          <p className="text-libelle-table-rate mb-0">
                            du {moment(item?.created_at).format("DD MMMM YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="content-col-table-rate">
                        {i === 0 && (
                          <p className="title-table-rate">Commentaire</p>
                        )} 
                        <div className="content-text-value-table-rate">
                          <p className="text-value-table-rate mb-0">
                            {getLabelRate(item?.note)}
                          </p>
                          <p className="text-libelle-table-rate mb-0">
                            {item?.commentaire}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null)} 
        </div>
      </div>
      <AvisProductModal show={show} setShow={setShow} product={product} />
      <ConnexionModal
				modalConnexion={modalConnexion}
				setModalConnexion={setModalConnexion}
			/>
    </div>
  );
}

export default AvisProduct;
