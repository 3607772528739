import React from 'react';
import './sidebar.css';
import { MdOutlineInsertChart } from 'react-icons/md';
import { CiShop } from 'react-icons/ci';
import { FaUsers } from 'react-icons/fa6';
import { FiUsers, FiShoppingCart } from 'react-icons/fi';
import { Link } from 'react-router-dom';


const Sidebar = () => {
  return (
    <div className="div-sidebar shadow-sm min-vh-100 mt-5 pt-5">
      <div className="pt-5">
        <ul className="nav nav-tabs flex-column fw-bold">
          <li className="nav-item mb-3">
            <Link className="nav-link" to="dashboard">
              <MdOutlineInsertChart className="me-2" />Dashboard
            </Link>
          </li>
          <li className="nav-item mb-3">
            <Link className="nav-link" to="Vendeurs">
              <FiUsers className="me-2" /> Vendeurs
            </Link>
          </li>
          <li className="nav-item mb-3">
            <Link className="nav-link" to="commandes">
              <CiShop className="me-2" /> Commandes
            </Link>
          </li>
          <li className="nav-item mb-3">
            <Link className="nav-link" to="produits">
              <FiShoppingCart className="me-2" /> Produits
            </Link>
          </li>
          <li className="nav-item mb-3">
            <Link className="nav-link" to="administrateurs">
              <FaUsers className="me-2" /> Administrateurs
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
