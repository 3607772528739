import React from "react";
import { MdOutlineAdd } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { useGetConfigAppQuery } from "../../../../utils/api/config/config.api";
import { createMarkup } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";

const CguAdmin = () => {
  const { data, isLoading } = useGetConfigAppQuery();
  return (
    <div className="cgu-admin">
      <div className="container-title-content-page mb-4">
        <div className="content-title">
          <h3 className="title-content-dash-admin">
            Conditions générales d'utilisation
          </h3>
        </div>
        <div className="content-btn-action-title-page-admin flex-r">
          <NavLink
            to="/admin/ajouter-cgu"
            className="btn btn-submit-form-add-product-admin"
            state={{
              type: "cgu",
            }}
          >
            <MdOutlineAdd />
            <span className="ps-2">Mettre à jour</span>
          </NavLink>
        </div>
      </div>
      <div className="content-text-policy-admin py-4">
        {!!isLoading && <TextSkeleton />}
        {!isLoading &&
          (data?.cgu ? (
            <p
              className="text-policy-admin"
              dangerouslySetInnerHTML={createMarkup(data?.cgu)}
            />
          ) : (
            <AlertInfo message="Le contenu des cgu n'est pas encore disponible" />
          ))}
      </div>
      {!!data?.cgu && (
        <div className="content-btn-action-title-page-admin flex-r">
          <NavLink
            to="/admin/ajouter-cgu"
            className="btn btn-submit-form-add-product-admin"
            state={{
              type: "cgu",
            }}
          >
            <MdOutlineAdd />
            <span className="ps-2">Mettre à jour</span>
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default CguAdmin;

export const TextSkeleton = () => {
  return (
    <>
      <div className="mb-4">
        <Skeleton
          className="text-policy-admin"
          count={30}
          style={{ lineHeight: "0.7rem" }}
        />
      </div>

      <div className="mb-4">
        <Skeleton
          className="text-policy-admin"
          count={30}
          style={{ lineHeight: "0.7rem" }}
        />
      </div>
      <div className="mb-4">
        <Skeleton
          className="text-policy-admin"
          count={30}
          style={{ lineHeight: "0.7rem" }}
        />
      </div>
    </>
  );
};
