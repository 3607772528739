import React from "react";
import Livreur from "../../assets/icons/livreur.png";
import Paiement from "../../assets/icons/chart.png";
import Assistant from "../../assets/icons/person.png";
import { AiOutlineSend } from "react-icons/ai";

const FooterPage = () => {
  return (
    <div className="newsletter-content">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="sm-title pb-2">Newsletter</div>
            <div className="sm-value">Restez informer</div>
          </div>
          <div className="col-md-9">
            <form action="">
              <div className="email-content">
                <input 
                  type="email" 
                  name="newsletter_email" 
                  id="newsletter_email" 
                  placeholder="Entrez votre e-mail..."
                />
                <button type="submit">
                  <AiOutlineSend />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterPage;
