import React from "react";
import PanierItemTable from "../../Panier/PanierItemTable";

const PanierValidStep = () => {
	return (
		<div className="content-form-register-tabs my-5">
			<div className="cart-items-table-content cart_step_wrapper">
				<PanierItemTable />
			</div>
		</div>
	);
};

export default PanierValidStep;
