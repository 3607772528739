/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../../common/Pagination";
import ListeCommandeTable from "./tables/ListeCommandeTable";
import { useLazyOrderByAcheteurQuery } from "../../../../utils/api/order/order.api";
import { useAppSelector } from "../../../../redux/hook";

const CommandePage = () => {
  const [page, setPage] = useState(1);

  const { user } = useAppSelector((s) => s?.user);

  const [findBySlug, { data = { results: [], count: 0 }, isLoading }] =
    useLazyOrderByAcheteurQuery();

  useEffect(() => {
    if (user?.slug) {
      findBySlug(user?.slug as string);
    }
  }, [user]);

  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="user-parcours-title">Mes commandes</h3>
      </div>
      <div className="content-detail-commande-page mt-3 mb-3">
        <ListeCommandeTable commandes={data?.results} isLoading={isLoading} />
      </div>
      <div className="d-flex justify-content-center">
        {data?.nbPage && data?.nbPage > 1 ? (
          <Pagination
            page={page}
            perPage={10}
            total={data?.nbPage}
            onPageChange={(page) => setPage(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CommandePage;

export const OrderSkeleton = () => {
  return (
    <>
      {Array(5)
        .fill(5)
        .map((item, i) => (
          <div className="detail-commande-item" key={i}>
            <div className="content-info-commande-item">
              <div className="content-img-commande-item">
                <Skeleton className="img-commande-item" height={150} />
              </div>
              <div className="info-commande-item">
                <Skeleton
                  className="text-name-commande-item mb-1"
                  width={`25rem`}
                />
                <Skeleton
                  className="text-price-commande-item mb-1"
                  width={`10rem`}
                />
                <Skeleton
                  className="text-date-pub-commande-item mb-2"
                  width={`12rem`}
                />
                <Skeleton className="statut-commande-item" width={`9rem`} />
              </div>
            </div>
            <div className="content-detail-commande-btn">
              <Skeleton
                className="btn btn-detail-commande"
                width={`5rem`}
                height={10}
              />
            </div>
          </div>
        ))}
    </>
  );
};
