import React from 'react';
import './dashboard.css';
import Card from './Card';
import { FaUser } from 'react-icons/fa';
import { AiFillStar } from 'react-icons/ai';
import EvolutionChart from '../DashBoard/EvolutionChart';
import { useGetDashboardAppQuery } from "../../../../utils/api/dashboard/dashboard.api";

const Dashboard = () => {

  const { data, isLoading } = useGetDashboardAppQuery();

  const listCard = [
    {
      id: 1,
      title: 'Total chiffre d affaires',
      nombre: 201,
      icon: <AiFillStar />,
      color: '#FBEDE7',
      effet: '#FF6422',
    },
    {
      id: 2,
      title: 'Total bénéfice',
      nombre: 44,
      icon: <FaUser />,
      color: '#E1F5FD',
      effet: '#4A4DE6',
    },
    {
      id: 3,
      title: 'Nombre de produits',
      nombre: 133,
      icon: <FaUser />,
      color: '#F2E7FC',
      effet: '#8C18E2',
    },
    {
      id: 4,
      title: 'Nombre de vendeurs',
      nombre: 201,
      icon: <AiFillStar />,
      color: '#E1F5FD',
      effet: '#A7D8A9',
    },
    {
      id: 5,
      title: 'Nombre de sous-vendeurs',
      nombre: 44,
      icon: <FaUser />,
      color: '#FBEDE7',
      effet: '#F46425',
    },
    {
      id: 6,
      title: 'Nombre de commandes',
      nombre: 133,
      icon: <FaUser />,
      color: '#FCFAE7',
      effet: 'yellow',
    },
  ];
  return (
    <div className="m-5">
      <div className="pt-5">
      <p>Filtrer
        <select className='ms-3 inpu' defaultValue="Tout">
          <option value="Tout">Tout</option>
          <option value="ChiffreAffaires">Total chiffre d'affaires</option>
          <option value="Benefice">Total bénéfice</option>
          <option value="Produits">Nombre de produits</option>
          <option value="Vendeurs">Nombre de vendeurs</option>
          <option value="SousVendeurs">Nombre de sous-vendeurs</option>
          <option value="Commandes">Nombre de commandes</option>
        </select>
      </p>
        
      </div>
      <div className="container pt-4  mt-3 cards-container">
        <div className="row">
          
          <div key="T-CA" className="col-md-4  mb-4">
            <div>
              <div
                className="card carte-dashboard p-3"
                style={{ backgroundColor: '#FBEDE7'}}
              >
                <h4>Total chiffre d'affaires</h4>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold text-dark">{data?.total_chiffre_affaire}</p>
                  <span style={{ color: '#FF6422' }}> <AiFillStar /> </span>
                </div>
              </div>
            </div>
          </div>
          <div key="T-Bénéfice" className="col-md-4 mb-4">
            <div>
              <div
                className="card carte-dashboard p-3"
                style={{ backgroundColor: '#E1F5FD'}}
              >
                <h4>Total bénéfice</h4>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold text-dark">{data?.total_benefice}</p>
                  <span style={{ color: '#4A4DE6' }}> <FaUser /> </span>
                </div>
              </div>
            </div>
          </div>
          <div key="T-Produits" className="col-md-4 mb-4">
            <div>
              <div
                className="card carte-dashboard p-3"
                style={{ backgroundColor: '#F2E7FC'}}
              >
                <h4>Nombre de produits</h4>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold text-dark">{data?.total_produits}</p>
                  <span style={{ color: '#8C18E2' }}> <FaUser /> </span>
                </div>
              </div>
            </div>
          </div>
          <div key="N-Vendeurs" className="col-md-4 mb-4">
            <div>
              <div
                className="card carte-dashboard p-3"
                style={{ backgroundColor: '#E1F5FD' }}
              >
                <h4>Nombre de vendeurs</h4>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold text-dark">{data?.total_vendeurs}</p>
                  <span style={{ color: '#A7D8A9' }}> <AiFillStar /> </span>
                </div>
              </div>
            </div>
          </div>
          <div key="N-S-Vendeurs" className="col-md-4 mb-4">
            <div>
              <div
                className="card carte-dashboard p-3"
                style={{ backgroundColor: '#FBEDE7' }}
              >
                <h4>Nombre de sous vendeurs</h4>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold text-dark">{data?.total_sous_vendeurs}</p>
                  <span style={{ color: '#F46425' }}> <FaUser /> </span>
                </div>
              </div>
            </div>
          </div>
          <div key="N-Commandes" className="col-md-4 mb-4">
            <div>
              <div
                className="card carte-dashboard p-3"
                style={{ backgroundColor: '#FCFAE7' }}
              >
                <h4>Nombre de commandes</h4>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold text-dark">{data?.total_commandes}</p>
                  <span style={{ color: 'yellow' }}> <FaUser /> </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <EvolutionChart graph={data} />

    </div>
  );
};

export default Dashboard;
