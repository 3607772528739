import React, { useEffect, Fragment, useState } from "react";
import { useGetAllCategoryListQuery} from "../../../../utils/api/category/category.api";
import { usePagination } from "../../../../utils/hooks";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSearchParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';


let options = [
  {
    label: "Nombre d'avis",
    value: "avreageRate",
  },
  {
    label: "Date de création",
    value: "createdAt",
  },
  {
    label: "Nom",
    value: "name",
  },
  {
    label: "Note moyenne",
    value: "rateCount",
  },
  {
    label: "Prix",
    value: "price",
  },
];

function MobileCategoryModal(
  { value, setValue, setSearchCategory,setSearchSousCategory, show, setShow, query, setQuery, catgoryId }
  ) { 
  const { data , refetch } = useGetAllCategoryListQuery();

  const handleSelectCategory = (id) => {
  
    setSearchCategory(id)
  
  };

  const handleSelectSousCategory = (id) => {
    setSearchSousCategory(id)
  };
  const handleClose = () => setShow(false);
  return ( 
    <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton className="b-b-1">
            <Offcanvas.Title>Filtrer</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <div className="py-3">
                <div className="custom_modal__content">

                  <div className="content-others-value my-4 pb-3">
                    <p className="content-title-section-left pb-2">Catégories</p>

                    <div className="categories_and_subcategories">
                      {!!data?.results &&
                        data?.results?.map((category, key) => (
                          <Fragment key={`${key}`}>
                              <Accordion defaultActiveKey="0" className="container-list-category-filter">
                                <Accordion.Item eventKey={key}>
                                  <Accordion.Header onClick={()=>handleSelectCategory(category?.id)}>
                                    <span className="category_name">
                                      {category?.nom}
                                    </span>
                                    {/* <span className="number">
                                      {category?.nbproduits} 
                                    </span> */}
                                  </Accordion.Header>
                                  {category?.sous_categorie && category?.sous_categorie?.length > 0 &&
                                      <Accordion.Body>
                                        <ul className="ul-list-category">
                                          {category?.sous_categorie.map((subc, key) => (
                                            <li className="list-category" key={`${key}_${subc.id}`}>
                                              <a href="#" className="sub_cat__item" onClick={()=>handleSelectSousCategory(subc?.id)}>
                                                <span className="category_name">
                                                  {subc.nom}
                                                </span>
                                                {/* <span className="number">
                                                  {subc?.nbproduits}
                                                </span> */}
                                              </a>
                                              {subc?.sous_categories && subc?.sous_categories?.length > 0 &&
                                              <ul className="ul-list-category m-3 mx-0">
                                                {subc?.list.map((sc, k) => (
                                                    <li className="list-category" key={`${k}_${sc.id}`}>
                                                        <a href="#" className="sub_cat__item" onClick={()=>handleSelectSousCategory(sc?.id)}>
                                                            <span className="category_name">
                                                              {sc?.nom}
                                                            </span>
                                                            <span className="number">
                                                              {subc?.nbproduits}
                                                            </span>
                                                        </a>
                                                    </li>
                                                ))}
                                              </ul>
                                              }
                                            </li>
                                          ))}
                                        </ul>
                                      </Accordion.Body>
                                  }  
                                </Accordion.Item>
                              </Accordion>
                          </Fragment>
                        ))
                      }
                    </div>

                            
                  </div>
                  <p className="content-title-section-left">
                    Prix <span style={{ fontWeight: 300, fontSize: 14 }}>(F CFA)</span>
                  </p>
                  <div className="pb-4 py-4">

                    <label className="material-checkbox mb-3" key='10'>
                      <input type="radio" value={10} name="prix" onChange={()=>setValue({min:0,max:10000})} />
                      <span>Jusqu'a 10 000</span>
                    </label>
                    <label className="material-checkbox mb-3" key='25'>
                      <input type="radio" value={25} name="prix" onChange={()=>setValue({min:10000,max:25000})} />
                        <span>De 10 000 à 25 000</span>
                    </label>
                    <label className="material-checkbox mb-3" key='50'>
                      <input type="radio" value={50} name="prix" onChange={()=>setValue({min:25000,max:50000})} />
                      <span>De 25 000 à 50 000</span>
                    </label>
                    <label className="material-checkbox mb-3" key='60'>
                      <input className='radio-input' type="radio" value={60} name="prix" onChange={()=>setValue({min:50000,max:900000000})} />
                      <span>De 50 000 et plus</span>
                    </label>
                  </div>
                </div>
            </div>
                      
        </Offcanvas.Body>
    </Offcanvas>
       
  );
}

export default MobileCategoryModal;
