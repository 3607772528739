/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  Contact,
  ContactFormData,
  ResponseContact,
  ResponseContactFormData,
} from "./contact.type";

export const ContactApi = createApi({
  reducerPath: "contactApi",
  tagTypes: ["contactList", "contactById", "responseByContact"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListContactAdmin: builder.query<PaginationResults<Contact>, TypeQuery>({
      providesTags: ["contactList"],
      query: (query) => QueryUrl("contact/", query),
    }),

    addOrEditContact: builder.mutation<Contact, ContactFormData>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(ContactApi.util.invalidateTags(["contactList"]));
        }, 1000);
      },
      query: ({ _id, ...data }) => {
        if (_id) {
          return {
            url: `contact/${_id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `contact/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteContact: builder.mutation<Contact, string>({
      query: (id) => ({
        url: `contact/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["contactList"],
    }),
    contactById: builder.query<Contact | any, string>({
      query: (id) => `contact/${id}/`,
      providesTags: ["contactById"],
    }),
    sendResponseContact: builder.mutation<
      ResponseContact,
      ResponseContactFormData
    >({
      query: (data) => ({
        url: "contact/response",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["contactList"],
    }),
  }),
});

export const {
  useAddOrEditContactMutation,
  useGetListContactAdminQuery,
  useSendResponseContactMutation,
} = ContactApi;
