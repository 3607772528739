import React, { useState , useEffect } from "react";
import { useLazyFavoriteByUserQuery } from "../../../utils/api/favories/favorie.api";
import { useAppSelector } from "../../../redux/hook";
import { AlertInfo } from "../../common/Alert";
import { ProductItem } from "../../HomePage/HomePage";
import Pagination from "../../common/Pagination";
import { ProductItemsSkeleton } from "../../user/ParcoursAchat/ParcoursAchat";

const FavorisPage = () => {
  const [page, setPage] = useState(1);
  const { user } = useAppSelector((s) => s?.user);

  const [findBySlug, { data, isError , isLoading }] = useLazyFavoriteByUserQuery();
  
  useEffect(() => {
      if (user?.slug) {
      findBySlug(user?.slug as string);
      }
  }, [user]);

  console.log('data',data)


  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-4">
        <h3 className="user-parcours-title">Mes favoris</h3>
      </div>
      <div className="content-panier-page mt-5">
        <div className="row row-panier-page">
          {!!isLoading && <ProductItemsSkeleton />}
          {!isLoading &&
            (data?.length ? (
              data?.map((item) => (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 col-favoris-page mb-3"
                  key={item?.id}
                >
                  <ProductItem item={item?.produit} />
                </div>
              ))
            ) : (
              <AlertInfo message="Aucun produit disponible dans vos favoris!" />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FavorisPage;
