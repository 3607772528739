import React, { useEffect, useState } from "react";
import UseRegisterForm from "../requestForm/UseRegisterForm";
import { FaUser } from "react-icons/fa";
import ErrorMessage from "../../common/ErrorMessage";
import { RiLockFill } from "react-icons/ri";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { MdMail, MdLocalPhone, MdLocationOn } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { useAuthContext } from "../context/AuthContext";
import { useAuthContextAPI } from "../context/AuthContext";
import { LoginSocialGoogle, LoginSocialApple } from "reactjs-social-login";
import { AppBaseUrl, GoogleClientId } from "../../../utils/http";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useSocialLogin } from "../requestForm/UseLoginForm";

function RegisterTab({ go, closeModalConnexion }) {
  const [propertyID, setPropertyID] = useState<string>("");
  const [client, setClient] = useState<any>();
  const [token, setToken] = useState<any>();

  // const onGetToken = () => {
  //   const PROPERTY_ID = '252665710140-5euq0h9htj81foss4ur1gllbbl8qtig9.apps.googleusercontent.com';
  //   client?.requestAccessToken();
  //   setPropertyID(PROPERTY_ID);
  // }

  // useEffect(() => {
    
  //   const CLIENT_ID: string | undefined = '252665710140-5euq0h9htj81foss4ur1gllbbl8qtig9.apps.googleusercontent.com' ;
    
  //   const client = (window as any)?.google?.accounts?.oauth2?.initTokenClient({
  //     client_id: CLIENT_ID as string,
  //     scope: "https://www.googleapis.com/auth/analytics.readonly",
  //     callback: (tokenResponse) => {
  //       setToken(tokenResponse.access_token);
  //     },
  //   });
    
  //   setClient(client);
  
  // }, []);

  const { onSubmit, register, isLoading, errors, handleChangePhone, phone } =
    UseRegisterForm(closeModalConnexion, go);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLodingSocialLogin, setIsLodingSocialLogin] = React.useState(false);
  const { loginWithGoogle } = useSocialLogin(closeModalConnexion,setIsLodingSocialLogin);


    return (
        <>
            <div className="social-connect-content d-flex align-items-center justify-content-center gap-3 py-4">
                {/* <button
                  type="button"
                  className="btn btn-social-connect d-flex align-items-center gap-2"
                >
                  <FaApple size={20} />
                  Se connecter avec Apple
                </button>  */}
              {!isLodingSocialLogin ? (
              <LoginSocialGoogle
                client_id={GoogleClientId}
                isOnlyGetToken={false}
                onLoginStart={() => console.log("login")}
                onResolve={loginWithGoogle}
                onReject={(err) => {
                  console.log(err);
                }}
                scope="profile email"
              >
                <button
                  type="button"
                  className="btn btn-social-connect d-flex align-items-center gap-2"
                >
                  <FcGoogle size={20} />
                  S'inscrire avec Google
                </button>
              </LoginSocialGoogle>
              )
              :
              'En cours ...'
              }
            </div>
            <div className="or-content text-center">
              <span>Ou</span>
            </div>
            <form id="auth-form" onSubmit={onSubmit}>
                <div className="row auth-form-row">
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <FaUser />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="recipient_firstname"
                          placeholder="Prenom"
                          aria-label="Prenom"
                          {...register("prenom")}
                        />
                        <ErrorMessage
                          message={errors?.prenom && errors?.prenom?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <FaUser />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="recipient_lastname"
                          placeholder="Nom"
                          aria-label="Nom"
                          {...register("nom")}
                        />
                        <ErrorMessage
                          message={errors?.nom && errors?.nom?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <MdMail />
                        </span>
                        <input
                          type={`email`}
                          className="form-control auth-form-control"
                          id="email-or-username"
                          placeholder="Email"
                          aria-label="Email"
                          {...register("email")}
                        />
                        <ErrorMessage
                          message={errors?.email && errors?.email?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 auth-input-col mb-3">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                        }}
                        defaultMask={".. ... .. .."}
                        country="sn"
                        value={phone}
                        enableSearch={true}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                        inputClass="form-control auth-form-control-telephone"
                        enableAreaCodes={true}
                        prefix="+"
                        countryCodeEditable={false}
                      />
                    </div>
                    <ErrorMessage
                        message={errors?.telephone && errors?.telephone?.message}
                      />
                    {/* <div className="col-md-12 auth-input-col mb-3">
                      <div className="auth-form-group">
                        <span className="icon-input">
                          <MdLocationOn />
                        </span>
                        <input
                          type={`text`}
                          className="form-control auth-form-control"
                          id="recipient_lastname"
                          placeholder="Adresse"
                          aria-label="Adresse"
                          {...register("adresse")}
                        />
                        <ErrorMessage
                          message={errors?.adresse && errors?.adresse?.message}
                        />
                      </div>
                    </div> */}
          <div className="col-md-12 auth-input-col mb-2">
            <div className="auth-form-group">
              <span className="icon-input">
                <RiLockFill />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control auth-form-control"
                id="password"
                placeholder="Mot de passe"
                aria-label="Password"
                {...register("password")}
              />
              <span
                className="show-hide-password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
            <ErrorMessage
              message={errors?.password && errors?.password?.message}
            />
          </div>
          <div className="col-md-12 auth-input-col mb-2">
            <div className="auth-form-group">
              <span className="icon-input">
                <RiLockFill />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control auth-form-control"
                id="confirmation_password"
                placeholder="Confirmation mot de passe"
                aria-label="Confirmation Password"
                {...register("confirmPassword")}
              />
              <span
                className="show-hide-password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
            <ErrorMessage
              message={
                errors?.confirmPassword && errors?.confirmPassword?.message
              }
            />
          </div>
          <div className="col-md-12 auth-input-col mb-2">
            <div className="accept_or_decline_wrapper pt-5">
              <div className="form-check custom_check__content d-flex align-items-center gap-2 p-0">
                <input
                  className="form-check-input m-0 "
                  type="checkbox"
                  {...register("is_accept_cgu")}
                  id="remember__me"
                />
                <label className="form-check-label" htmlFor="remember__me">
                  J’accepte les&nbsp;
                  <a
                    href={`${AppBaseUrl}mentions-legales`}
                    target="_blank"
                    className="redirect_page_link"
                  >
                    conditions d’utilisations
                  </a>
                  &nbsp; et la&nbsp;
                  <a
                    href={`${AppBaseUrl}politique-de-confidentialite`}
                    target="_blank"
                    className="redirect_page_link"
                  >
                    politique de confidentialité
                  </a>
                </label>
              </div>
              <ErrorMessage
                message={
                  errors?.is_accept_cgu && errors?.is_accept_cgu?.message
                }
              />
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center pt-5">
            <button
              // to="/admin/dashboard"
              className="btn auth-submit-btn trans-0-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Inscription...</span>
                </>
              ) : (
                "Inscription"
              )}
            </button>
          </div>
          <div
            id="SignInDiv"
            className="col-md-12 d-flex justify-content-center pt-5"
          ></div>
          {/* <button className="btn auth-submit-btn trans-0-2" onClick={onGetToken}>Obtenir un jeton d'accès</button> */}
        </div>
      </form>
    </>
  );
}

export default RegisterTab;
