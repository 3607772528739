import React , { useState,useEffect } from 'react';
import { CiSearch } from "react-icons/ci";
import img1 from '../../../../assets/image-admin/img-vendeurs.jpg'
import { MdEmail } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import './admin.css';
import { usePagination } from "../../../../utils/hooks";
import { useGetListAdminUserQuery} from "../../../../utils/api/user/user.api";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { AiFillEye } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { IUser } from "../../../../utils/api/user/user.type";
import { formatDate, isAdmin, isPreparateur,formatCurrency} from "../../../../utils/Utils";
import { useAppSelector } from "../../../../redux/hook";
import { CustomPagination } from "../../GestionProduits/ProductsTable/ProductsTable";
import { getAvatar } from "../../../../utils/Utils";
import  AddOrUpdateSubAdminModal from "./modale/AddOrUpdateSubAdminModal"
import { MdEdit } from "react-icons/md";
import { BsTrashFill } from "react-icons/bs";
import { DeleteUser } from "./modale/hooks/UseAddOrUpdateSubAdminForm";


const UpdateSubAdminButton = ({ admin }: { admin: IUser }) => {
  const [showUpdateSubAdminModal, setShowUpdateSubAdminModal] =
      useState<boolean>(false);
  const handleShowUpdateSubAdminModal = () =>
      setShowUpdateSubAdminModal(true);
  const handleCloseUpdateSubAdminModal = () =>
      setShowUpdateSubAdminModal(false);
  return (
      <>
          <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              onClick={handleShowUpdateSubAdminModal}
          >
              <MdEdit className="edit-action-icon" />
          </button>
          <AddOrUpdateSubAdminModal
              show={showUpdateSubAdminModal}
              handleClose={handleCloseUpdateSubAdminModal}
              admin={admin}
          />
      </>
  );
};

const Administration = () => {
  const [search, setSearch] = useState<string>("");
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading, refetch } = useGetListAdminUserQuery({
    page: page,
    search: search,
    limit: perPage,
  });

  const [showAddModalSubAdmin, setShowAddModalSubAdmin] = useState<boolean>(false);
const handleShowAddModalSubAdmin = () => setShowAddModalSubAdmin(true);
const handleCloseAddModalSubAdmin = () => setShowAddModalSubAdmin(false);


  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            {/* <NavLink
              to={`/admin/utilisateur/${row?.slug}`}
              state={row}
              className="btn btn-action-filter-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink> */}

            <UpdateSubAdminButton admin={row} />
            
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteUser item={row} />
          </div>
        </div>
      </>
    );
  };

  const nameFormatter = (cell: string, row: IUser) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        {/* <div className="content-img-user-table">
          <img
            loading="lazy"
            src={getAvatar(row?.avatar)}
            alt="Produit"
            className="img-user-table"
          />
        </div> */}
        <p className="text-nom-produit-table mb-0">
          {row?.prenom + " " + cell}
        </p>
      </div>
    );
  };


  const columns = [
    
    {
      dataField: "nom",
      text: "Prenom et Noms",
      formatter: (cell: string, row: IUser) => nameFormatter(cell, row),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "id",
      text: "Actions",
      formatter: (cell: string, row: any) => actionFormatter(cell, row),
    },
  ];

  const onSearchChange =(e:any) =>{
      setSearch(e.target.value) ;
  }

  useEffect(() => {
    if (search) {
        refetch();
    }
  }, [search]);

  return (
  
    <div className="pt-5">
      <div className="d-flex justify-content-between m-5 items-center ">
        <h4 className='fw-bold title-form-register'>Administrateur</h4>
        <div className="d-flex gap-3 item-center relative">
          <div className="absolute">
            <input type="text" onChange={(e)=>onSearchChange(e)} placeholder='Recherche' className='input-vendeur' /><CiSearch className='input-icon me-3' />
          </div>
          <button onClick={handleShowAddModalSubAdmin} className='btn btn-admin'>Ajouter Administrateur</button>
          <AddOrUpdateSubAdminModal
              show={showAddModalSubAdmin}
              handleClose={handleCloseAddModalSubAdmin}
          />
        </div>
      </div>
      <div className="m-3">
        {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.results || []}
              columns={columns}
              striped
              condensed
              hover
              responsive={true}
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => "Aucune donnée trouvée"}
              //pagination={paginationFactory({ sizePerPage: 10 })}
            />
            <CustomPagination
              nbPages={data?.nbPage}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Administration;
