import React from "react";
import { ProductItem } from "../../HomePage/HomePage";
import { useGetProductListQuery } from "../../../utils/api/product/product.api";
import { usePagination } from "../../../utils/hooks";
import { Product } from "../../../utils/api/product/product.type";
import { ProductItemsSkeleton } from "./ParcoursAchat";
import { Col, Row } from "react-bootstrap";
import { AlertInfo } from "../../common/Alert";


function ProductSimilary({ product }: { product: Product }) {
  const { limit } = usePagination(13);
  const { data, isLoading } = useGetProductListQuery({
    categorie: product?.categorie?.slug,
    sous_categorie: product?.sous_categorie?.slug || "",
    limit,
  });

  return (
    <div className="container-produits-similaires mb-5">
      <h3 className="title-produits-similaires">Produits similaires</h3>
      <Row className="py-4">
        {!!isLoading && <ProductItemsSkeleton />}

        {!isLoading &&
          (data?.results?.filter((el) => el?.id !== product?.id)?.slice(0, 12)?.length ? (
            data?.results
              ?.filter((el) => el?.id !== product?.id)
              ?.slice(0, 12)
              ?.map((produit, i) => (
                <Col
                  className="col-produits-similaires mb-4 pb-4 col-content-product-home"
                  sm={6}
                  xs={6}
                  md={4}
                  lg={3}
                  key={i}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="product-item-carousel">
                    <ProductItem item={produit} key={i} />
                  </div>
                </Col>
              ))
          ) : (
            <div className="col-sm-12 col-md-12 mb-3 m-t-36 d-flex justify-content-center">
              <AlertInfo message="Aucun produit similaire disponible pour le moment" />
            </div>
          ))}
      </Row>
      {/* {produits?.length > 12 && (
          <div className="d-flex justify-content-center">
              <Pagination
                  page={page}
                  total={data?.results?.length || 0}
                  onPageChange={(page: number) => onChange(page, perPage)}
              />
          </div>
      )} */}
    </div>
  );
}

export default ProductSimilary;
