import React from "react";
import { NavLink } from "react-router-dom";
import Deco from "../../assets/images/appImages/deco.png";
import { usePagination } from "../../utils/hooks";
import {
  useGetProductListQuery,
  useGetProductsEnAvantQuery,
} from "../../utils/api/product/product.api";
import { ProductItemsSkeleton } from "../user/ParcoursAchat/ParcoursAchat";
import AliceCarousel from "react-alice-carousel";
import { getVendeur, ProductItem } from "./HomePage";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "../../redux/hook";
import { formatCurrency, getImage, truncateCaractere } from "../../utils/Utils";
import Empty from "../../assets/images/appImages/category.png";

export const responsiveI = {
  0: { items: 1 },
  576: { items: 1 },
  768: { items: 1 },
  992: { items: 1 },
  1024: { items: 1 },
};
function BannerHomepage() {
  let { limit, page } = usePagination(12);
  const { data = { results: [] }, isLoading } = useGetProductListQuery({
    page: page,
    limit: limit,
  });
  const { data: produitsEnAvant } = useGetProductsEnAvantQuery({
    page: page,
    limit: limit,
  });
  return (
    <section className="section-banner-homepage">
      <div className="show_new">Nouveau</div>
      <div className="row align-items-center">
        <div className="col-md-7 col-left-banner-accueil mb-3 d-flex">
          <div className="content-col-left-banner-accueil">
            {/* <h2 className="mb-0 title-home-page">Avec Awa Bio Foods,</h2>
            <h2 className="title-home-page">La nature s'exprime</h2>
            <div className="content-text-home-page-banner mt-2 mb-5">
              <p className="text-home-page-banner"></p>
            </div>
            <div className="container-btn-banner-accueil">
              <NavLink className="btn btn-title-home-page" to="/produits">
                Voir nos produits
              </NavLink>
            </div> */}
            <div className="new_products__carousel position-relative">
              {!!isLoading && <BannerNewProductItemSkeleton />}
              {!isLoading && (
                <AliceCarousel
                  mouseTracking
                  responsive={responsiveI}
                  autoPlayStrategy="default"
                  controlsStrategy="alternate"
                  autoPlay={true}
                  infinite={true}
                  keyboardNavigation={true}
                  autoPlayInterval={6000}
                >
                  {produitsEnAvant?.count && produitsEnAvant?.count > 0
                    ? !!produitsEnAvant &&
                      produitsEnAvant?.results
                        ?.slice(
                          Math.max(produitsEnAvant?.results?.length - 10, 0)
                        )
                        ?.map((produit, key) => (
                          <div
                            className="col-product-item"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <BannerNewProductItem item={produit} key={key} />
                          </div>
                        ))
                    : !!data &&
                      data?.results
                        ?.slice(Math.max(data?.results?.length - 10, 0))
                        ?.map((produit, key) => (
                          <div className="col-product-item product-item-carousel">
                            <BannerNewProductItem item={produit} key={key} />
                          </div>
                        ))}
                </AliceCarousel>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-5 col-right-banner-accueil mb-3 no-view-mobile">
          <div className="content-col-right-banner-accueil">
            <div className="banner_left__last_products">
              {!!isLoading && <BannerNewProductItemSkeleton />}
              {!isLoading &&
              produitsEnAvant?.count &&
              produitsEnAvant?.count > 0
                ? !!produitsEnAvant &&
                  produitsEnAvant?.results
                    ?.slice(Math.max(produitsEnAvant?.results?.length - 2, 0))
                    ?.map((produit, key) => (
                      <div
                        className="col-product-item"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <BannerNewProductItem item={produit} key={key} />
                      </div>
                    ))
                : !!data &&
                  data?.results
                    ?.slice(Math.max(data?.results?.length - 2, 0))
                    ?.map((produit, key) => (
                      <div className="col-product-item product-item-carousel">
                        <BannerNewProductItem item={produit} key={key} />
                      </div>
                    ))}
            </div>

            {/* <div className="container-img-deco-banner">
              <img
                loading="lazy"
                src={Deco}
                alt="Deco"
                className="img-deco-banner"
              />
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="dark_filter"></div> */}
    </section>
  );
}

export default BannerHomepage;

export const BannerNewProductItem = ({ item }: { item: any }) => {
  const { user, token } = useAppSelector((s) => s?.user);
  const Vendeur = getVendeur();

  const imgProd =
    item?.type == "taille_unique"
      ? item?.images[0]?.image
      : item?.variations && item?.variations[0]?.images
      ? item?.variations[0]?.images[0]?.image
      : null;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return (
    <>
      <div className="new_product__item no-view-mobile">
        <div className="row product-row">
          <div className="col-7">
            <div className="image-wrapper">
              <img
                src={imgProd ? getImage(imgProd) : Empty}
                alt={item?.nom}
                className="p-image"
              />
            </div>
          </div>
          <div className="col-5">
            <div className="product-infos">
              <div className="custom_container">
                <h3 className="name-product p-name">
                  {window.innerWidth < 720
                    ? truncateCaractere(item?.nom, 39)
                    : truncateCaractere(item?.nom, 72)}
                </h3>
                <p className="py-2 custom_sm__title p-category">
                  {item?.categorie?.nom}
                </p>
                <p className="m-0 pb-3 text-describe-product-item-detail p-description">
                  {truncateCaractere(item?.description, 14)}...
                  <NavLink
                    to={`/produit/${item?.slug}`}
                    className="no-link p-link"
                  >
                    Voir plus
                  </NavLink>
                </p>
                <div className="content-price-product">
                  {item?.categorie?.promotion ||
                  item?.sous_categorie?.promotion ||
                  item?.promotion ? (
                    <>
                      <span className="price-product-item-promo pb-3">
                        {formatCurrency(
                          item?.prix -
                            (item?.prix *
                              (item?.categorie?.promotion?.taux ||
                                item?.sous_categorie?.promotion?.taux ||
                                item?.promotion?.taux)) /
                              100,
                          Vendeur?.devise
                        )}{" "}
                      </span>
                      <span className="price-product-item-bare pb-3">
                        {formatCurrency(item?.prix, Vendeur?.devise)}
                      </span>
                    </>
                  ) : (
                    <p className="price-product-item d-flex">
                      {formatCurrency(item?.prix, Vendeur?.devise)}
                    </p>
                  )}
                </div>
                <div className="container-btn-banner-accueil">
                  <NavLink
                    className="btn btn-title-home-page"
                    to={`/produit/${item?.slug}`}
                  >
                    Acheter
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="product-item-container no-view-desktop mb-3"
        style={{ flexGrow: 1 }}
      >
        <NavLink
          to={`/produit/${item?.slug}`}
          state={item}
          className="no-link w-100"
        >
          {item?.new === true ? (
            <span className="statut-product-item">Nouveau</span>
          ) : item?.stock == 0 ? (
            <span className="statut-product-rupture">Rupture de stock</span>
          ) : (
            ""
          )}
          {(item?.categorie?.promotion ||
            item?.sous_categorie?.promotion ||
            item?.promotion) && (
            <span className="statut-product-item">
              Promo -
              {item?.categorie?.promotion?.taux ||
                item?.sous_categorie?.promotion?.taux ||
                item?.promotion?.taux}
              %
            </span>
          )}

          <div className="content-img-product-item">
            <img
              loading="lazy"
              src={imgProd ? getImage(imgProd) : Empty}
              alt={item?.nom}
              className="img-product-item"
            />
          </div>
          <div className="container-body-product-item">
            <div className="content-element-body-product-item">
              <p className="name-product-item my-2">
                {window.innerWidth < 720
                  ? truncateCaractere(item?.nom, 39)
                  : truncateCaractere(item?.nom, 72)}
              </p>
              <div className="content-price-product">
                {item?.categorie?.promotion ||
                item?.sous_categorie?.promotion ||
                item?.promotion ? (
                  <>
                    <span className="price-product-item-promo pb-3">
                      {formatCurrency(
                        item?.prix -
                          (item?.prix *
                            (item?.categorie?.promotion?.taux ||
                              item?.sous_categorie?.promotion?.taux ||
                              item?.promotion?.taux)) /
                            100,
                        Vendeur?.devise
                      )}{" "}
                    </span>
                    <span className="price-product-item-bare pb-3">
                      {formatCurrency(item?.prix, Vendeur?.devise)}
                    </span>
                  </>
                ) : (
                  <p className="price-product-item d-flex">
                    {formatCurrency(item?.prix, Vendeur?.devise)}
                  </p>
                )}
              </div>
            </div>
            <div className="container-cta-chat-produit">
              {item?.stock > 0 ? (
                <div className="content-btn-achat-produit w-100">
                  <NavLink
                    to={`/produit/${item?.slug}`}
                    className="btn btn-theme w-100"
                  >
                    Acheter
                  </NavLink>
                </div>
              ) : (
                <div className="content-btn-achat-produit w-100">
                  <button className="btn btn-theme-outline w-100 disabled">
                    Acheter
                  </button>
                </div>
              )}
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
};
const BannerNewProductItemSkeleton = () => {
  return (
    <>
      <div className="new_product__item">
        <div className="row product-row">
          <div className="col-7">
            <div className="image-wrapper">
              <Skeleton width="100%" height={350} />
            </div>
          </div>
          <div className="col-5">
            <div className="product-infos">
              <div className="custom_container">
                <Skeleton className="name-product p-name" width={100} />
                <Skeleton
                  className="my-2 custom_sm__title p-category"
                  width={100}
                />
                <div>
                  <Skeleton
                    className="m-0 mb-3 text-describe-product-item-detail p-description"
                    width={100}
                  />
                </div>
                <div className="container-btn-banner-accueil">
                  <Skeleton className="btn btn-title-home-page" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
