import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { Product } from "../product/product.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Favorie, FavorieFormData } from "./favorie.type";


export const FavoriteApi = createApi({
  reducerPath: "favoriteApi",
  tagTypes: [
    "favorite",
    "favoriteList",
    "favoriteByVisitor",
    "favoriteById",
    "allFavoriteList",
    "favoriteByUser"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListFavoriteAdmin: builder.query<PaginationResults<Favorie>, TypeQuery>({
      providesTags: ["favoriteList"],
      query: (query) => QueryUrl("favorite/paginate", query),
    }),
    getFavoriteList: builder.query<Product[], TypeQuery>({
      providesTags: ["favorite"],
      query: (query) => QueryUrl("favorite/me", query),
    }),
    favoriteByUser: builder.query<Favorie | any, string>({
      query: (slug) => `favoris/${slug}/user/`,
      providesTags: ["favoriteByUser"],
    }),
    addOrDeleteFavorite: builder.mutation<Favorie,{ slug?: string; data: FavorieFormData | FormData }>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            FavoriteApi.util.invalidateTags([
              "favoriteList",
              "favoriteByVisitor",
              "favorite",
            ])
          );
        }, 1000);
      },
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `favoris/${slug}/`,
            method: "DELETE",
            body: data,
          };
        }
        return {
          url: `favoris/`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useAddOrDeleteFavoriteMutation,
  useLazyFavoriteByUserQuery,
  useGetFavoriteListQuery,
  useGetListFavoriteAdminQuery,
  useLazyGetFavoriteListQuery
} = FavoriteApi;
