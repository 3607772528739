import React from 'react'
import { HiHome } from 'react-icons/hi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

function BreadCrumb(
	{ pageName, title, secondPage }: 
	{ pageName: string; title?: string, secondPage?: string }
) {
    return (
		<div className="breadcrumb-component">
			<section className="breadcrumb-container container-fluid">
				<div className="breadcrumb-page-title-content-body flex-sb-mobile">
					<div className="breadcrumb-page-container-title-content-body gap-2">
						<h1 className="page-title">{title}</h1>
						<div className="breadcrumb-row d-flex align-items-center gap-2">
							<div className="base-home-container d-flex align-items-center gap-2">
								<div className="home-label-container">
									<NavLink
										to="/"
										className="home_breadcrumb_link"
									>
										{" "}
										<span className="home-label">
											Accueil
										</span>
									</NavLink>
								</div>
								<div className="arrow-icon-container">
									<MdKeyboardArrowRight className="arrow-icon" />
								</div>
							</div>
							<div className="second-level-breadcrumb-container other_level_link">
								<span className="breadcrumb-second-level-label">
									{" "}
									{pageName}{" "}
								</span>
							</div>
							<div className="arrow-icon-container">
								<MdKeyboardArrowRight className="arrow-icon" />
							</div>
							<div className="third-level-breadcrumb-container other_level_link">
								<span className="breadcrumb-second-level-label">
									{" "}
									{secondPage}{" "}
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default BreadCrumb
