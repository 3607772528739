import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Category, CategoryFormData } from "./category.type";

export const CategoryApi = createApi({
  reducerPath: "categoryApi",
  tagTypes: [
    "category",
    "categoryList",
    "categoryByVisitor",
    "categoryById",
    "allCategoryList",
    'categoryByVendeur'
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListCategory: builder.query<PaginationResults<Category>, TypeQuery>({
      providesTags: ["categoryList"],
      query: (query) => QueryUrl("visiteur/categories", query),
    }),
    getCategoryList: builder.query<Category[], void>({
      providesTags: ["category"],
      query: () => {
        return "visiteur/categories/";
      },
    }),
    getAllCategoryList: builder.query<any , void>({
      providesTags: ["allCategoryList"],
      query: () => {
        return "visiteur/categories/";
      },
    }),
    addOrEditCategory: builder.mutation<Category,{ id?: string; data: CategoryFormData | FormData }>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            CategoryApi.util.invalidateTags([
              "categoryList",
              "categoryByVisitor",
              "category",
              "allCategoryList",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `categories/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `categories/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteCategory: builder.mutation<Category, string>({
      query: (id) => ({
        url: `categories/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "category",
        "categoryList",
        "categoryByVisitor",
      ],
    }),
    categoryById: builder.query<Category | any, string>({
      query: (id) => `categories/${id}/`,
      providesTags: ["categoryById"],
    }),
    categoryByVendeur: builder.query<Category | any, number>({
      query: (id) => `categories/user/${id}/`,
      providesTags: ["categoryByVendeur"],
    }),
  }),
});

export const {
  useAddOrEditCategoryMutation,
  useGetListCategoryQuery,
  useDeleteCategoryMutation,
  useLazyCategoryByIdQuery,
  useCategoryByVendeurQuery,
  useGetCategoryListQuery,
  useGetAllCategoryListQuery
} = CategoryApi;

export function useCategoryFromLocation(): [
  Category,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Category | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyCategoryByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/admin/produits", {
        replace: true,
      });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data",data)
      setItem(data);
    }
  }, [data]);

  return [item as Category, isLoading, id as string, findById];
}
