import React ,{useState, useEffect}  from 'react';
import './vendeurs.css';
import { CiSearch } from "react-icons/ci";
import img1 from '../../../../assets/image-admin/img-vendeurs.jpg'
import { MdEmail } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { Link, NavLink } from 'react-router-dom';
import { CustomPagination } from "../../GestionProduits/ProductsTable/ProductsTable";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { IUser } from "../../../../utils/api/user/user.type";
import { DeleteProduit } from "../Administration/modale/hooks/UseAddOrUpdateSubAdminForm";
import { useGetListvendeurAdminQuery} from "../../../../utils/api/vendeur/vendeur.api";
import { useAppSelector } from "../../../../redux/hook";
import { formatDate, isAdmin, isPreparateur,formatCurrency} from "../../../../utils/Utils";
import { AiFillEye } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";



const Vendeurs = () => {

  const [search, setSearch] = useState<string>("");
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading, refetch } = useGetListvendeurAdminQuery({
    page: page,
    search: search,
    limit: perPage,
  });


  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/vendeurs/${row?.slug}`}
              state={row}
              className="btn btn-action-filter-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>            
          </div>
          <div className="container-btn-action-icon-modal">

            <div className="container-btn-action-icon-modal">
              <NavLink
                to={`/admin/modifierVendeur/${row?.slug}`}
                state={row}
                data-tooltip-content="Modifier"
                className="btn btn-action-modal-icon with-tooltip"
                data-bs-target={`#editAdminModal${cell}`}
              >
                <MdModeEditOutline />
              </NavLink>            
            </div>
            
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteProduit item={row} />
          </div>
        </div>
      </>
    );
  };

  const nameFormatter = (cell: string, row: any) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        
        <p className="text-nom-produit-table mb-0">
          {row?.vendeur?.prenom + " " + row?.vendeur?.nom}
        </p>
      </div>
    );
  };

  const teleFormatter = (cell: string, row: any) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        
        <p className="text-nom-produit-table mb-0">
          {row?.vendeur?.telephone }
        </p>
      </div>
    );
  };

  const dateFormatter = (cell: string, row: any) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        
        <p className="text-nom-produit-table mb-0">
          {formatDate(row?.created_at) }
        </p>
      </div>
    );
  };

  const columns = [
    
    {
      dataField: "nom",
      text: "Noms",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "telephone",
      text: "Telephone",
    },
    {
      dataField: "nom_de_la_boutique",
      text: "Boutique",
    },
    {
      dataField: "date",
      text: "Date de Creation",
      formatter: (cell: string, row: IUser) => dateFormatter(cell, row),
    },
    {
      dataField: "id",
      text: "Actions",
      formatter: (cell: string, row: any) => actionFormatter(cell, row),
    },
  ];

  const onSearchChange =(e:any) =>{
      setSearch(e.target.value) ;
  }

  useEffect(() => {
    if (search) {
        refetch();
    }
  }, [search]);
  
  return (
    <div className='pt-5'>
      <div className="d-flex justify-content-between m-5 items-center ">
        <h4 className='fw-bold title-form-register'>Vendeurs</h4>
        <div className="d-flex gap-3 item-center relative">
          <div className="">
            <input type="text" placeholder='Recherche' className='input-vendeur' /><CiSearch className='input-icon absolute me-3' />
          </div>
          <Link to="/admin/ajoutVendeur">
            <button className='btn btn-vendeur'>+ Ajouter un vendeurs</button>
          </Link>
        </div>
      </div>
      <div className="m-3">
        {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.results || []}
              columns={columns}
              striped
              condensed
              hover
              responsive={true}
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => "Aucune donnée trouvée"}
            />
            <CustomPagination
              nbPages={data?.nbPage}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Vendeurs;
