import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Notification } from "./notification.type";

export const NotificationApi = createApi({
  reducerPath: "notificationApi",
  tagTypes: ["notification", "notificationList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListNotificationAdmin: builder.query<
      PaginationResults<Notification>,
      TypeQuery
    >({
      providesTags: ["notificationList"],
      query: (query) => QueryUrl("notification", query),
    }),
    readNotification: builder.mutation<Notification, string>({
      query: (id) => ({
        url: `notification/read/${id}/`,
        method: "GET",
      }),
      invalidatesTags: ["notificationList"],
    }),
  }),
});

export const { useGetListNotificationAdminQuery, useReadNotificationMutation } =
  NotificationApi;
