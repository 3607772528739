import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { ProductApi } from "../product/product.api";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Rate, RateFormData } from "./rate.type";

export const RateApi = createApi({
  reducerPath: "rateApi",
  tagTypes: ["rate", "rateList", "rateByProduct", "rateById", "allRateList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListRateAdmin: builder.query<PaginationResults<Rate>, TypeQuery>({
      providesTags: ["rateList"],
      query: (query) => QueryUrl("rate/paginate", query),
    }),
    getRateListByProduct: builder.query<any, TypeQuery>({
      providesTags: ["rateByProduct"],
      query: ({ product, ...query }) =>
        QueryUrl(`avis/${product}/produit`, query),
    }),
    addRate: builder.mutation<Rate, RateFormData>({
      invalidatesTags: ["rateByProduct"],
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(ProductApi.util.invalidateTags(["productBySlug", "productPromote","product"]));
        }, 1000);
      },
      query: (data) => {
        return {
          url: `avis/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteRate: builder.mutation<Rate, string>({
      query: (id) => ({
        url: `avis/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["rate", "rateList"],
    }),
    RateById: builder.query<Rate | any, string>({
      query: (id) => `avis/${id}/`,
      providesTags: ["rateById"],
    }),
  }),
});

export const { useAddRateMutation, useGetRateListByProductQuery } = RateApi;
