import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "../../utils/api/product/product.type";
import { AppStorage } from "../../utils/storage";


type Cart = {
  cartItems: CartItemType[];
  totalCount: number;
  tax: number;
  delivery: number;
  subAmount: number;
  totalAmount: number;
};

export type CartItemType = {
  produit: Product ;
  prix: number ;
  quantite: number;
  stock: number;
  variations: any;
  vendeur: number
};
const initialState: Cart = {
  cartItems: [],
  totalCount: 0,
  delivery: 0,
  tax: 0,
  subAmount: 0,
  totalAmount: 0,
};
const useCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCartProduct: (state, action: PayloadAction<CartItemType>) => {
      let cartIndex = state.cartItems.findIndex(
        (item) => (item?.produit === action.payload?.produit && item?.variations[0]?.id === action.payload.variations[0]?.id)
      );
 
      if (cartIndex  !== -1) {
     
          state.cartItems[cartIndex].quantite += action?.payload?.quantite;
     
      } else {
        let tempProduct = { ...action.payload };
        state.cartItems.push(tempProduct);
      }
    },
    getCartProducts: (state) => {
      return {
        ...state,
      };
    },
    getCartCount: (state) => {
      let cartCount = state.cartItems.reduce((total, item) => {
        return item?.quantite + total;
      }, 0);
      state.totalCount = cartCount;
    },
    // getSubTotal: (state) => {
    //   state.subAmount = state.cartItems.reduce((acc, item) => {
    //     return (
    //       acc +
    //       (item?.product?.promote?.price || item?.product?.prix) * item?.quantite
    //     );
    //   }, 0);
    // },
    removeCartItem: (state, action: PayloadAction<CartItemType>) => {
      let index = state.cartItems.findIndex(
        (item) => (item?.produit?.id === action.payload?.produit?.id && item?.variations[0]?.id === action.payload.variations[0]?.id)
      );

      console.log('index',index)
      if (index !== -1) {
        state.cartItems.splice(index, 1);
      }
    },
    increment: (state, action: PayloadAction<CartItemType>) => {
      let index = state.cartItems.findIndex(
        (item) => (item?.produit?.id === action.payload?.produit?.id && item?.variations[0]?.id === action.payload.variations[0]?.id)
      );
      if(state.cartItems[index].stock > state.cartItems[index].quantite )
      {
        state.cartItems[index].quantite += 1;
      }
    },
    decrement: (state, action: PayloadAction<CartItemType>) => {
      let index = state.cartItems.findIndex(
        (item) => (item?.produit?.id === action.payload?.produit?.id && item?.variations[0]?.id === action.payload.variations[0]?.id)
      );
      if (state.cartItems[index].quantite <= 0) {
        state.cartItems[index].quantite = 0;
        state.cartItems.splice(index, 1);
      } else {
        state.cartItems[index].quantite -= 1;
      }
    },
    calculateTax: (state) => {
      // GST value: 18% => action.payload
      let totalTax = (18 / 100) * state.subAmount;
      state.tax = totalTax;
    },
    getDeliveryAmount: (state,action:PayloadAction<number>) => {
     
      state.delivery = action.payload;
    },
    getTotalAmount: (state) => {
      let somme = 0
      state?.cartItems.forEach(element => {
        somme = somme + (element?.quantite * element?.prix)
      });
      state.totalAmount = somme ;
    },
    removeCart: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  addCartProduct,
  getCartProducts,
  removeCartItem,
  getCartCount,
  //getSubTotal,
  increment,
  decrement,
  calculateTax,
  getTotalAmount,
  removeCart,
  getDeliveryAmount
} = useCartSlice.actions;

export default useCartSlice.reducer;
