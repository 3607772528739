import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { VendeurFormData,Vendeur } from "../../../../utils/api/vendeur/vendeur.type";
import {
    useAddOrEditVendeurMutation,
    useDeleteVendeurMutation
} from "../../../../utils/api/vendeur/vendeur.api";
import { Color } from "../../../../utils/theme";
import { cleannerError, getImage } from "../../../../utils/Utils";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../utils/hooks";
import { BsTrashFill } from "react-icons/bs";
import { Link, NavLink, useParams, useLocation, useNavigate} from 'react-router-dom';


function UseAddOrUpdateVendeurForm(vendeur?: Vendeur) {
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        prenom: yup.string().required().label("Le prenom"),
        nom: yup.string().required().label("Le nom"),
        email: yup.string().email().required().label("L'email"),
        user_type: yup.string().required().label("Le type"),
        telephone: yup.string().required().label("Le téléphone"),
        domaine: yup.string().required().label("Le domaine"),
        couleur: yup.string().required().label("Le couleur"),
        nom_de_la_boutique: yup.string().required().label("Le nom de la boutique"),
    
                
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        clearErrors,
        control,
    } = useForm<VendeurFormData>({
        resolver: yupResolver(validationSchema),
    });

    const [cardImg, setCardImg] = useState<any>(null);
    const [image, setImage] = useState<File | null>(null);

    const [addOrEditVendeur, { isLoading, isSuccess, error, isError, data }] =
        useAddOrEditVendeurMutation();

    useEffect(() => {
        if (isSuccess) {
            Swal.fire({
                icon: "success",
                title: `Vendeur ${vendeur ? "modifié" : "ajouté"} avec succès !`,
                iconColor: Color.success,
                showConfirmButton: false,
                timer: 3000,
            }).then(() => {
                reset();
                navigate("/admin/Vendeurs")
            });
        }
        const err = error as any;
        if (isError) {
            console.log("error", err);
            if (
                err?.data?.email?.includes(
                    "Un utilisateur avec cet email existe déjà."
                )
            ) {
                Swal.fire({
                    icon: "error",
                    title: "Un utilisateur avec cet email existe déjà.",
                    showConfirmButton: false,
                    timer: 3000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: err?.data?.message
                        ? err?.data?.message
                        : `Une erreur de statut ${err?.status} est survenue `,
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }
    }, [error, isError, isSuccess]);
    useEffect(() => {
        cleannerError(errors, clearErrors);
    }, [clearErrors, errors]);

    useEffect(() => {
        if (image?.name) {
            //setValue("avatar", image);
            setCardImg(URL.createObjectURL(image));
        }
    }, [image]);

    const handleChangeAvatar = (e: any) => {
        setImage(e.target.files[0]);
        setValue('avatar', e.target.files[0])
    }

    useEffect(() => {
        if (vendeur?.id) {
            let fields: (keyof VendeurFormData)[] = [
                "id",
                "slug",
                "prenom",
                "nom",
                "email",
                "user_type",
                "telephone",
                "nom_de_la_boutique",	
                "couleur",	
                "domaine",
                "devise",
                "adresse",
                "wave_api_key",
                "wave_api_secret_key",
                "stripe_publishable_key",	
                "stripe_secret_key"	,
                "stripe_endpoint_secret"
            ];
            for (let field of fields) {
                register(field);
                if (field) {
                    setValue(field, vendeur[field]);
                }
            }
            if (vendeur?.avatar) {
                setCardImg(getImage(vendeur?.avatar));
            }
        }
    }, [vendeur]);

    const onSubmit = async (data: VendeurFormData) => {
        const fd = new FormData();
        for (let key of Object.keys(data)) {
            fd.append(key, data[key]);
        }
        await addOrEditVendeur({ slug: vendeur?.slug, data: fd });
    };

    return {
        register,
        errors: errors,
        onSubmit: handleSubmit(onSubmit),
        setValue,
        isLoading,
        control,
        image,
        cardImg,
        handleChangeAvatar
    };
}

export function DeleteVendeur({ item }: { item: Vendeur }) {
    const [deleteItem, { isSuccess, isError, error }] = useDeleteVendeurMutation();
    const onDelete = useDelete<Vendeur>({
        item,
        deleteItem,
        isSuccess,
        isError,
        error,
        question: "cet vendeur",
        successMessage: "Vendeur supprimé",
    });
    return (
        <button
            
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content="Supprimer"
            style={{ color: "#E53E3E" }}
            onClick={onDelete}
        >
            <BsTrashFill />
        </button>
    );
}


export default UseAddOrUpdateVendeurForm;
