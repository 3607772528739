import React ,{useState, useEffect} from 'react';
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import './produit.css';
import { CustomPagination } from "../../GestionProduits/ProductsTable/ProductsTable";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { IUser } from "../../../../utils/api/user/user.type";
import { DeleteProduit } from "../Administration/modale/hooks/UseAddOrUpdateSubAdminForm";
import { useGetListProductAdminQuery} from "../../../../utils/api/product/product.api";
import { useAppSelector } from "../../../../redux/hook";


const Produits = () => {

  const [search, setSearch] = useState<string>("");
  const { user } = useAppSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading, refetch } = useGetListProductAdminQuery({
    page: page,
    search: search,
    limit: perPage,
  });


  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            {/* <NavLink
              to={`/admin/utilisateur/${row?.slug}`}
              state={row}
              className="btn btn-action-filter-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink> */}

            {/* <UpdateSubAdminButton admin={row} /> */}
            
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteProduit item={row} />
          </div>
        </div>
      </>
    );
  };

  const nameFormatter = (cell: string, row: any) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        
        <p className="text-nom-produit-table mb-0">
          {row?.vendeur?.prenom + " " + row?.vendeur?.nom}
        </p>
      </div>
    );
  };

  const teleFormatter = (cell: string, row: any) => {
    return (
      <div className="table-actions-btn-container-img gap-2">
        
        <p className="text-nom-produit-table mb-0">
          {row?.vendeur?.telephone }
        </p>
      </div>
    );
  };

  const columns = [
    
    {
      dataField: "nom",
      text: "Noms",
    },
    {
      dataField: "prix",
      text: "Prix de vente",
    },
    {
      dataField: "id",
      text: "Vendeurs",
      formatter: (cell: string, row: IUser) => nameFormatter(cell, row),
    },
    {
      dataField: "id",
      text: "Actions",
      formatter: (cell: string, row: any) => actionFormatter(cell, row),
    },
  ];

  const onSearchChange =(e:any) =>{
      setSearch(e.target.value) ;
  }

  useEffect(() => {
    if (search) {
        refetch();
    }
  }, [search]);

  return (
    <div className='pt-5'>
      <div className="d-flex justify-content-between m-5 items-center ">
        <h4 className='fw-bold title-form-register'>Produits</h4>
        <div className="d-flex gap-3 item-center relative">
          <div className="absolute">
            <input type="text" placeholder='Recherche' className='input-vendeur' /><CiSearch className='input-icon absolute me-3' />
          </div>
          <form action="" className="d-flex form item-center gap-3">
            <select
              className="form-select form-input"
              aria-label="Default select example"
            >
              <option selected>filter</option>
              <option value="1">One</option>
            </select>
          </form>
          <Link to="#">
            <button className='btn btn-produit'>Exporter Les Donnees</button>
          </Link>
        </div>
      </div>
      <div className="m-3">
        {isLoading && <TableSkeleton headers={columns} />}
        {!isLoading && (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.results || []}
              columns={columns}
              striped
              condensed
              hover
              responsive={true}
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => "Aucune donnée trouvée"}
            />
            <CustomPagination
              nbPages={data?.nbPage}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Produits;
