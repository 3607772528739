import React from "react";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import MonCompteSidebar from "./MonCompteSidebar";
import { Outlet } from "react-router-dom";

const MonCompteLayout = () => {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content pb-5">
        <div className="container-easy-market">
          <div className="row user-parcours mb-5">
            <div className="col-md-3 custom-side-bar">
              <div className="col-sidebar-content">
                <MonCompteSidebar />
              </div>
            </div>
            <div className="col-md-9">
              <div className="content-col-right-register-page">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FooterPage /> */}
      <FrontFooter />
    </div>
  );
};

export default MonCompteLayout;
