import { useEffect,useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData ,SocialRegisterFormData} from "../../../utils/api/auth/auth.type";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useLoginUserMutation,useLoginUserWithSocialMediaMutation } from "../../../utils/api/auth/auth.api";
import { onSetUserToken , onSetUserTokenOnLocalStorage } from "../../../redux/slice/User.slice";
import { cleannerError, showModal, convertToLowerExceptFirst } from "../../../utils/Utils";
import { AppStorage,AppLocalStorage } from "../../../utils/storage";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { IResolveParams } from "reactjs-social-login";
import { UserType } from "../../../utils/api/user/user.type";
import { isSuperAdmin, isAdmin } from "../../../utils/Utils";
// import { useLazyMeAdminQuery } from "../../../utils/api/admin/admin.api";



function UseLoginForm(closeModalConnexion: () => void) {
  const validationSchema = yup.object().shape({
    email: yup.string().required().label("L'email ou le numero de téléphone"),
    password: yup.string().required().label("Le mot de passe"),
  });
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    reset,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  const user = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const [phone, setPhone] = useState<string>("");
  const [code, setCode] = useState<string>("");


  const [loginUser,{isLoading, isSuccess, error, isError, data }] = useLoginUserMutation();

  
    const handleChangePhone = (item, country) => {
      setCode(country?.countryCode);
      setPhone("+" + item);
      setValue("email",item);
    };


  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: LoginFormData) => {

	const { remember_me, ...rest } = data;

    const res:any = await loginUser(data);

    if ("data" in res) {

      Swal.fire({
			icon: "success",
			title: "Connexion reussie!",
			iconColor: Color.success,
			showConfirmButton: false,
			timer: 2000,
		}).then(() => {

			if (remember_me) {
				AppLocalStorage.setItem(
					"remember",
					remember_me ? 1 : 0
				);
				dispatch(
					onSetUserTokenOnLocalStorage({
						user: res.data,
						token: res.data.token,
					})
				);
			} else {
				
				dispatch(
					onSetUserToken({ 
						user: res?.data, 
						token: res?.data?.token 
					}));
			}
			
			if(isSuperAdmin(res?.data?.data) || isAdmin(res?.data?.data))
			{
				closeModalConnexion()
				navigate("/admin/dashboard")
			}else{
				closeModalConnexion()
			}
        
		});
        
    }

    if ("error" in res) {
			const err = res.error as any;
			let message = err?.data?.message
				? err?.data?.message
				: err?.status < 500
				? "Email/Téléphone ou mot de passe incorrect"
				: `Une erreur de statut ${err?.status} est survenue.`;
			setError("email", {
				message: message,
			});
		}
      

  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    handleChangePhone,
    phone
  };
}

export function socialLogin(closeModalConnexion: () => void, setIsLodingSocialLogin:any) {
	const navigate = useNavigate();
	const [socialLoginUser] = useLoginUserWithSocialMediaMutation();
	const dispatch = useAppDispatch();


	// const [fetchMe] = useLazyMeAdminQuery();
	const onsocialLogin = async (data: SocialRegisterFormData) => {
	
		const res = await socialLoginUser(data);

		if ("data" in res) {

    	const { data } = res;

		Swal.fire({
			icon: "success",
			title: "Connexion reussie!",
			iconColor: Color.success,
			showConfirmButton: false,
			timer: 1200,
		}).then(() => {
				
				AppLocalStorage.setItem("remember", 1);
				dispatch(
					onSetUserTokenOnLocalStorage({
						user: res.data,
						token: res.data.token,
					})
				);
			
				dispatch(
					onSetUserToken({
						user: data,
						token: data?.token,
					})
				);
				
          		closeModalConnexion()
				setIsLodingSocialLogin(false)
				// Swal.fire({
				// 	icon: "success",
				// 	title: "Veillez modifier votre numero de téléphone avant de continuer .",
				// 	iconColor: Color.success,
				// 	showConfirmButton: false,
				// 	timer: 2000,
				// });
				// navigate("/mon-compte/profil")
				
			});
		}
		if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			setIsLodingSocialLogin(false)
			const {
				message = "Une erreur a survenue lors de la connexion",
			} = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			});
		}
	};

	return onsocialLogin;
}

export function useSocialLogin(closeModalConnexion: () => void, setIsLodingSocialLogin:any) {
	const onSocialLogin = socialLogin(closeModalConnexion,setIsLodingSocialLogin);
	
	const loginWithGoogle = ({ data }: IResolveParams) => {
		
		setIsLodingSocialLogin(true)		
		const authUserData: SocialRegisterFormData = {
			email: data?.email as string,
			prenom: data?.given_name ? data?.given_name : data?.name,
			nom: data?.family_name ? convertToLowerExceptFirst(data?.family_name) : convertToLowerExceptFirst(data?.name) ,
			telephone: '999'
		};

		if (authUserData?.email) {
			onSocialLogin(authUserData);
		} else {
			setIsLodingSocialLogin(false)
			Swal.fire({
				icon: "info",
				title: "Email non retourné !",
				showConfirmButton: false,
				timer: 1200,
			});
		}
	};

	return {
		loginWithGoogle,
	};
}

export default UseLoginForm;
