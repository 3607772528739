import React from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import Logo from '../../../../assets/image-admin/em.png'
import { IoLogOut } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import { MdInsertChartOutlined } from "react-icons/md";
import { onlogout } from "../../../../redux/slice/User.slice";
import { useAppDispatch } from "../../../../redux/hook";
import { useNavigate, useSearchParams } from "react-router-dom";


const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(onlogout());
    navigate("/")
  };

  return (
    <Navbar bg="light" expand="lg" className='bg fixed-top'>
      <Container>
        <Navbar.Brand href="/">
          <img
            src={Logo}
            alt="Logo"
            width="80"
            height="30"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Dropdown>
            <Dropdown.Toggle variant="outline-light" className='text-dark border'>
              AKN
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item href="/admin/Dashboard"><MdInsertChartOutlined className='me-2' />Dashboard</Dropdown.Item>
              <Dropdown.Item href="/admin/Profil"><IoPersonOutline className='me-2' />Profil</Dropdown.Item>

              <Dropdown.Item onClick={() => onLogout()} className='text-danger'> <IoLogOut className='me-2' />
                Déconnexion</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
