import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { UserFormData } from "../../../../../../utils/api/user/user.type";
import {
    useAddOrEditAdminMutation,
    useDeleteUserMutation
} from "../../../../../../utils/api/user/user.api";
import {
    useDeleteOrderMutation
} from "../../../../../../utils/api/order/order.api";
import {
    useDeleteProductMutation
} from "../../../../../../utils/api/product/product.api";
import { Color } from "../../../../../../utils/theme";
import { cleannerError, getImage } from "../../../../../../utils/Utils";
import { IUser } from "../../../../../../utils/api/user/user.type";
import { FaTrash } from "react-icons/fa";
import { useDelete } from "../../../../../../utils/hooks";
import { BsTrashFill } from "react-icons/bs";


function UseAddOrUpdateSubAdminForm(handleClose: any, admin?: IUser) {
    const validationSchema = yup.object().shape({
        prenom: yup.string().required().label("Le prenom"),
        nom: yup.string().required().label("Le nom"),
        email: yup.string().email().required().label("L'email"),
        user_type: yup.string().required().label("Le type"),
        telephone: yup.string().required().label("Le téléphone"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        clearErrors,
        control,
    } = useForm<UserFormData>({
        resolver: yupResolver(validationSchema),
    });

    const [cardImg, setCardImg] = useState<any>(null);
    const [image, setImage] = useState<File | null>(null);

    const [addOrEditAdmin, { isLoading, isSuccess, error, isError, data }] =
        useAddOrEditAdminMutation();

    useEffect(() => {
        if (isSuccess) {
            Swal.fire({
                icon: "success",
                title: `Sous admin ${admin ? "modifié" : "ajouté"} avec succès !`,
                iconColor: Color.success,
                showConfirmButton: false,
                timer: 3000,
            }).then(() => {
                handleClose();
                reset();
            });
        }
        const err = error as any;
        if (isError) {
            console.log("error", err);
            if (
                err?.data?.email?.includes(
                    "Un utilisateur avec cet email existe déjà."
                )
            ) {
                Swal.fire({
                    icon: "error",
                    title: "Un utilisateur avec cet email existe déjà.",
                    showConfirmButton: false,
                    timer: 3000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: err?.data?.message
                        ? err?.data?.message
                        : `Une erreur de statut ${err?.status} est survenue `,
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }
    }, [error, isError, isSuccess]);
    useEffect(() => {
        cleannerError(errors, clearErrors);
    }, [clearErrors, errors]);

    useEffect(() => {
        if (image?.name) {
            //setValue("avatar", image);
            setCardImg(URL.createObjectURL(image));
        }
    }, [image]);

    const handleChangeAvatar = (e: any) => {
        setImage(e.target.files[0]);
        setValue('avatar', e.target.files[0])
    }

    useEffect(() => {
        if (admin?.id) {
            let fields: (keyof UserFormData)[] = [
                "id",
                "slug",
                "prenom",
                "nom",
                "email",
                "user_type",
                "telephone"
            ];
            for (let field of fields) {
                register(field);
                if (field) {
                    setValue(field, admin[field]);
                }
            }
            if (admin?.avatar) {
                setCardImg(getImage(admin?.avatar));
            }
        }
    }, [admin]);

    const onSubmit = async (data: UserFormData) => {
        const fd = new FormData();
        for (let key of Object.keys(data)) {
            fd.append(key, data[key]);
        }
        await addOrEditAdmin({ slug: admin?.slug, data: fd });
    };

    return {
        register,
        errors: errors,
        onSubmit: handleSubmit(onSubmit),
        setValue,
        isLoading,
        control,
        image,
        cardImg,
        handleChangeAvatar
    };
}

export function DeleteUser({ item }: { item: IUser }) {
    const [deleteItem, { isSuccess, isError, error }] = useDeleteUserMutation();
    const onDelete = useDelete<IUser>({
        item,
        deleteItem,
        isSuccess,
        isError,
        error,
        question: "cet utilisateur",
        successMessage: "Utilisateur supprimé",
    });
    return (
        <button
            
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content="Supprimer"
            style={{ color: "#E53E3E" }}
            onClick={onDelete}
        >
            <BsTrashFill />
        </button>
    );
}

export function DeleteCommande({ item }: { item: any }) {
    const [deleteItem, { isSuccess, isError, error }] = useDeleteOrderMutation();
    const onDelete = useDelete<any>({
        item,
        deleteItem,
        isSuccess,
        isError,
        error,
        question: "cet commande",
        successMessage: "commande supprimé",
    });
    return (
        <button
            
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content="Supprimer"
            style={{ color: "#E53E3E" }}
            onClick={onDelete}
        >
            <BsTrashFill />
        </button>
    );
}

export function DeleteProduit({ item }: { item: any }) {
    const [deleteItem, { isSuccess, isError, error }] = useDeleteProductMutation();
    const onDelete = useDelete<any>({
        item,
        deleteItem,
        isSuccess,
        isError,
        error,
        question: "cet produit",
        successMessage: "produit supprimé",
    });
    return (
        <button
            
            className="btn with-tooltip btn-action-icon-delete"
            data-tooltip-content="Supprimer"
            style={{ color: "#E53E3E" }}
            onClick={onDelete}
        >
            <BsTrashFill />
        </button>
    );
}

export default UseAddOrUpdateSubAdminForm;
