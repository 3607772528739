import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Ressource, RessourceFormData } from "./ressource.type";

export const RessourceApi = createApi({
  reducerPath: "ressourceApi",
  tagTypes: [
    "ressource",
    "ressourceList",
    "ressourceByVisitor",
    "ressourceById",
    "allRessourceList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListRessourceAdmin: builder.query<PaginationResults<Ressource>, TypeQuery>({
      providesTags: ["ressourceList"],
      query: (query) => QueryUrl("resource/paginate", query),
    }),
    getRessourceList: builder.query<Ressource[], TypeQuery>({
      providesTags: ["ressource"],
      query: (query) => QueryUrl("resource/", query),
    }),
    addOrEditRessource: builder.mutation<
      Ressource,
      { id?: string; data: RessourceFormData | FormData }
    >({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            RessourceApi.util.invalidateTags([
              "ressourceList",
              "ressourceByVisitor",
              "ressource",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `resource/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `resource/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteRessource: builder.mutation<Ressource, string>({
      query: (id) => ({
        url: `resource/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ressource", "ressourceList", "ressourceByVisitor"],
    }),
    ressourceById: builder.query<Ressource, string>({
      query: (id) => `resource/${id}/`,
      providesTags: ["ressourceById"],
    }),
  }),
});

export const {
  useAddOrEditRessourceMutation,
  useGetListRessourceAdminQuery,
  useGetRessourceListQuery,
  useDeleteRessourceMutation
} = RessourceApi;