import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo ,useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForgetPasswordMutation } from "../../../utils/api/auth/auth.api";
import { ForgetPasswordFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/Utils";
// import { useForgetPasswordMutation } from "../../../../utils/api/auth/auth.api";
// import { ForgetPasswordFormData } from "../../../../utils/api/auth/auth.type";
// import { Color } from "../../../../utils/theme";
// import { cleannerError } from "../../../../utils/utils";
export function UseForgetPasswordForm(
  closeModalForgetPassword?: () => void,
  openModalResetPassword?: () => void
) {
  const [forgetPassword, { error, isSuccess, isLoading, originalArgs }] = useForgetPasswordMutation();
  const navigate = useNavigate();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        telephone: yup.string().required().label("Téléphone"),
      }),
    []
  );

  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setValue
  } = useForm<ForgetPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone("+" + item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("telephone", "+" + item);
    } else {
      setValue("telephone", "");
    }
  };

  
  useEffect(() => {
    if (error) {
      const err = error as any;
      console.log("erreur", err);
      const message = err?.data?.message || "Une erreur a survenue lors de l'envoi";
      setError("telephone", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      if (closeModalForgetPassword && openModalResetPassword) {
        openModalResetPassword();
        closeModalForgetPassword();
        
      }
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Un mail vous a été envoyé, vérifiez s'il vous plaît!",
        timer: 2500,
      }).then(() => {
        if (!closeModalForgetPassword) {
          navigate("/modifier-mot-de-passe", {
            replace: true,
            state: { email: originalArgs?.telephone },
          });
        }
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const onSubmit = async (data: ForgetPasswordFormData) => {
    data["telephone"] = data.telephone;
    await forgetPassword(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    isLoading,
    handleChangePhone,
    phone
  };
}
