/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useState } from "react";
import { IQuery, QueryError } from "./type";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "./theme";

export function useDelete<T>(props: IQuery<T>) {
	const {
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question,
		successMessage,
		redirectUrl,
		close,
		archive,
	} = props;
	const navigate = useNavigate();
	const onDelete = useCallback(async () => {
		Swal.fire({
			title: `Êtes vous sûr de vouloir ${
				archive ? "achiver" : "supprimer"
			} ${question}`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.danger,
			confirmButtonColor: Color.danger,
			preConfirm: () => {
				// @ts-ignore
				deleteItem(item?.slug);
			},
		});
	}, []);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `${successMessage} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				if (redirectUrl) {
					navigate(redirectUrl);
				}
				close && close();
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as QueryError;
			console.log('err',err);
			
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	return onDelete;
}

export function useDeleteById<T>(props: IQuery<T>) {
	const { item, deleteItem, isSuccess, isError, error, question, successMessage, redirectUrl } =
		props;
	const navigate = useNavigate();
	const onDelete = useCallback(async () => {
		Swal.fire({
			title: `Êtes vous sûr de vouloir supprimer ${question}`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.danger,
			confirmButtonColor: Color.danger,
			preConfirm: () => {
				// @ts-ignore
				deleteItem(item?.id);
			},
		});
	}, []);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `${successMessage} avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				if (redirectUrl) {
					navigate(redirectUrl);
				}
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as QueryError;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}
export function usePagination(initialPerPageValue: number) {
	const [page, setPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(initialPerPageValue);
	const onChange = (page: number, perPage: number) => {
		setPerPage(perPage);
		setPage(page);
	};
	return {
		page,
		setPage,
		limit: perPage,
		setPerPage,
		perPage,
		onChange,
	};
}

export function useModal<T>() {
	const [isShowModal, setIsShowModal] = useState(false);
	const toggle = () => setIsShowModal((isShowModal) => !isShowModal);
	const [item, setItem] = useState<T>();
	const openModal = () => {
		setItem(undefined);
		toggle();
	};
	const openEditModal = (item?: T) => {
		setItem(item);
		toggle();
	};
	const getItem = (item: T) => {
		setItem(item);
	};
	return {
		isShowModal,
		isOpen: isShowModal,
		toggle,
		openModal,
		openEditModal,
		openDetailModal: openEditModal,
		closeModal: openModal,
		item,
		getItem,
	};
}

export function useItem<T>() {
	const [item, setItem] = useState<T>();
	const getItem = (item: T) => {
		setItem(item);
	};
	return {
		item,
		getItem,
	};
}

export function useSearch(defaultValue?: string) {
	const [search, setSearch] = useState<string | undefined>(defaultValue);
	const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.value && e.currentTarget.value.length >= 3) {
			setSearch(e.currentTarget.value);
		} else {
			setSearch("");
		}
	};

	return {
		search,
		handleSearch,
	};
}

export function useSelectSearch() {
	const [search, setSearch] = useState<string>();
	const handlefilter = (e: React.FormEvent<HTMLSelectElement>) => {
		setSearch(e.currentTarget.value);
	};
	return {
		search,
		handlefilter,
	};
}

export function useToggleModal() {
	const [isShowModal, setIsShowModal] = useState(false);
	const toggle = () => setIsShowModal((isShowModal) => !isShowModal);

	return {
		isShowModal,
		toggle,
		isOpen: isShowModal,
		openModal: toggle,
		closeModal: toggle,
	};
}

export function useToggle() {
	const [isActive, setIsActive] = useState<boolean>(false);
	const toggleActive = () => {
		setIsActive((isActive) => !isActive);
	};

	return {
		isActive,
		toggleActive,
		isOpen: isActive,
		toggleOpen: toggleActive,
	};
}

export function useTogglePassword() {
	const [showPassword, setShowPassword] = useState<boolean>();
	const toggleShowPassword = () => {
		setShowPassword((showPassword) => !showPassword);
	};
	return {
		showPassword,
		toggleShowPassword,
	};
}
