import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import CommandeDetailTable from "./tables/CommandeDetailsTable";
import { useLazyOrderBySlugQuery } from "../../../../utils/api/order/order.api";
import {
  formatCurrency,
} from "../../../../utils/Utils";
import { getVendeur } from "../../../HomePage/HomePage";


function CommandeDetail() {
  const Vendeur = getVendeur();

  const navigate = useNavigate();

  const { slug } = useParams<{ slug: string }>();

  const [findBySlug, { data, isLoading }] = useLazyOrderBySlugQuery();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);

  return (
    <div className="custom-content">
      <div className="action-btn-container">
        <button className="go-back-btn" onClick={() => navigate(-1)}>
          <MdKeyboardBackspace />
          Retour
        </button>
      </div>
      <div className="content-detail-commande-page detail-page mt-3 mb-3">
        <div className="table-content">
          <CommandeDetailTable
            articles={data?.produits}
            isLoading={isLoading}
          />
        </div>
        <div className="separator"></div>
        <div className="payment-mode-content pt-4">
          <div className="row row-payment-mode">
            <div className="col-md-7">
              <div className="payment pb-4">
                <div className="sm-title pb-2">Mode de paiement</div>
                <div className="sm-value">
                  {data?.mode_de_paiement == "ORANGE_SN_API_CASH_OUT" &&
                    "Orange Money"}
                  {data?.mode_de_paiement == "WAVE_SN_API_CASH_OUT" && "Wave"}
                  {data?.mode_de_paiement == "CASH" &&
                    "Paiement à la livraison"}
                  {data?.mode_de_paiement == "BANK_TRANSFER_SN_API_CASH_OUT" &&
                    "Virement Bancaire"}
                </div>
              </div>
              <div className="separator"></div>
              <div className="payment pt-4 pb-4">
                <div className="sm-title pb-2">Statut de la commande</div>
                <div className="sm-value">
                  {data?.statut == "EN_COURS_DE_TRAITEMENT" &&
                    "En cours de taitement"}
                  {data?.statut == "ANNULE" && "Annelé"}
                  {data?.statut == "EN_COURS_DE_LIVRAISON" &&
                    "En cour de livraison"}
                  {data?.statut == "LIVRE" && "Livré"}
                </div>
              </div>
              <div className="separator"></div>
            </div>
            <div className="col-md-5">
              <div className="price-and-total-content">
                <div className="subprice d-flex justify-content-between align-items-center pb-3">
                  <span className="sm-value">Prix sous-total</span>
                  <span className="sm-title">{formatCurrency(data?.montant_total,Vendeur?.devise)}</span>
                </div>
                <div className="fraissup d-flex justify-content-between align-items-center pb-3">
                  <span className="sm-value">Frais de livraison :</span>
                  <span className="sm-title">À définir</span>
                </div>
                <div className="maintotal d-flex justify-content-between align-items-center">
                  <span className="sm-value">Total :</span>
                  <span className="sm-title total">
                    {formatCurrency(data?.montant_total,Vendeur?.devise)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommandeDetail;
