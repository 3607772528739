/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Zone, ZoneFormData } from "./zone.type";

export const ZoneApi = createApi({
  reducerPath: "zoneApi",
  tagTypes: ["zone", "zoneList", "zoneByVisitor", "zoneById", "allZoneList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getZoneList: builder.query<PaginationResults<Zone>, TypeQuery>({
      providesTags: ["zoneList"],
      query: (query) => QueryUrl("zones/", query),
    }),
    getZoneListByVisitor: builder.query<Zone[], void>({
      providesTags: ["zoneByVisitor"],
      query: () => "zones/for-visitor/",
    }),
    addOrEditZone: builder.mutation<Zone, ZoneFormData>({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            ZoneApi.util.invalidateTags(["zoneList", "zoneByVisitor", "zone"])
          );
        }, 1000);
      },
      query: ({ _id, ...data }) => {
        if (_id) {
          return {
            url: `zones/${_id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `zones/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteZone: builder.mutation<Zone, string>({
      query: (id) => ({
        url: `zones/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["zone", "zoneList", "zoneByVisitor"],
    }),
    zoneById: builder.query<Zone | any, string>({
      query: (id) => `zones/${id}/`,
      providesTags: ["zoneById"],
    }),
  }),
});

export const {
  useAddOrEditZoneMutation,
  useDeleteZoneMutation,
  useGetZoneListQuery,
  useLazyZoneByIdQuery,
  useGetZoneListByVisitorQuery
} = ZoneApi;
