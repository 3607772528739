import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../../../common/ErrorMessage";
import { useAppSelector } from "../../../../../redux/hook";
import { CiMail } from "react-icons/ci";
import GoogleInput from "../../../../common/GoogleInput";
import { FormError } from "../../../../common/Input";

const AdresseLivraison = ({
  register,
  errors,
  handleChangePhone,
  phone,
  onChangeAdresse,
  adresse,
}) => {
  const { user } = useAppSelector((s) => s?.user);

  return (
    <div className="form-register-tabs-component mt-5">
      <p className="custom_validate_cart_step__title">Livraison</p>
      <div className="infos_livraison_container pt-4">
        <div className="row">
          {!user && (
            <>
              <div className="col-md-7 mb-3">
                <div className="input_wrapper position-relative">
                  <span className="icon_wrapper">
                    <AiOutlineUser size={22} />
                  </span>
                  <input
                    className="form-control form-control-register-tabs"
                    type="text"
                    {...register("prenom_client")}
                    placeholder="Prenom "
                  />
                  <ErrorMessage
                    message={
                      errors?.prenom_client && errors?.prenom_client?.message
                    }
                  />
                </div>
              </div>
              <div className="col-md-7 mb-3">
                <div className="input_wrapper position-relative">
                  <span className="icon_wrapper">
                    <AiOutlineUser size={22} />
                  </span>
                  <input
                    className="form-control form-control-register-tabs"
                    type="text"
                    {...register("nom_client")}
                    placeholder="Nom "
                  />
                  <ErrorMessage
                    message={errors?.nom_client && errors?.nom_client?.message}
                  />
                </div>
              </div>
              <div className="col-md-7 mb-3">
                <div className="input_wrapper position-relative">
                  <span className="icon_wrapper">
                    <CiMail size={22} />
                  </span>
                  <input
                    className="form-control form-control-register-tabs"
                    type="text"
                    {...register("email_client")}
                    placeholder="Email"
                  />
                  <ErrorMessage
                    message={
                      errors?.email_client && errors?.email_client?.message
                    }
                  />
                </div>
              </div>
            </>
          )}
          <div className="col-md-7 mb-3">
            <div className="input_wrapper">
              <PhoneInput
                inputProps={{
                  name: "phone",
                }}
                defaultMask={".. ... .. .."}
                country="sn"
                value={phone}
                enableSearch={true}
                onChange={(phone, country) => handleChangePhone(phone, country)}
                inputClass="form-control-register-tabs-tel"
                enableAreaCodes={true}
                prefix="+"
                countryCodeEditable={false}
              />
            </div>
            <ErrorMessage
              message={errors?.telephone && errors?.telephone?.message}
            />
          </div>
          <ErrorMessage
            message={
              errors?.mode_de_paiement && errors?.mode_de_paiement?.message
            }
          />
          <div className="col-md-7 mb-3">
            <div className="input_wrapper position-relative">
              <span className="icon_wrapper">
                <HiOutlineLocationMarker size={22} />
              </span>
              <GoogleInput
                className="form-control form-control-register-tabs"
                value={adresse}
                onChange={onChangeAdresse}
                placeholder="Adresse"
              />
              {/* <input
								className="form-control form-control-register-tabs"
								type="text"
								placeholder="Ouest Foire"
								{...register("adresse")}
								defaultValue={user?.adresse}
							/> */}
              <ErrorMessage
                message={errors?.adresse && errors?.adresse?.message}
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="mb-3">
              <textarea
                id="informations_complementaires"
                className={`form-control form-control-modal-custom`}
                placeholder="Informations complementaires (Nº appartement, etc.)"
                cols={30}
                rows={5}
                {...register("informations_complementaires")}
              ></textarea>
              {
                <FormError
                  error={errors?.informations_complementaires?.message}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdresseLivraison;
