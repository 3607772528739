import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { TypeQuery } from "../user/user.type";
import { PaymentMode, PaymentModeFormData } from "./paymentMode.type";

export const PaymentModeApi = createApi({
  reducerPath: "paymentModeApi",
  tagTypes: [
    "paymentMode",
    "paymentModeList",
    "paymentModeByVisitor",
    "paymentModeById",
    "allPaymentModeList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getListpaymentModeAdmin: builder.query<PaymentMode[], TypeQuery>({
      providesTags: ["paymentModeList"],
      query: (query) => QueryUrl("payment-method/for-admin", query),
    }),
    getPaymentModeList: builder.query<PaymentMode[], TypeQuery>({
      providesTags: ["paymentMode"],
      query: (query) => QueryUrl("payment-method/", query),
    }),
    addOrEditPaymentMode: builder.mutation<
      PaymentMode,
      { id?: string; data: PaymentModeFormData | FormData }
    >({
      onCacheEntryAdded(arg, { dispatch }) {
        setTimeout(() => {
          dispatch(
            PaymentModeApi.util.invalidateTags([
              "paymentModeList",
              "paymentModeByVisitor",
              "paymentMode",
            ])
          );
        }, 1000);
      },
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `payment-method/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `payment-method/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deletePaymentMode: builder.mutation<PaymentMode, string>({
      query: (id) => ({
        url: `payment-method/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "paymentMode",
        "paymentModeList",
        "paymentModeByVisitor",
      ],
    }),
    paymentModeById: builder.query<PaymentMode | any, string>({
      query: (id) => `payment-method/${id}/`,
      providesTags: ["paymentModeById"],
    }),
  }),
});

export const {
  useAddOrEditPaymentModeMutation,
  useDeletePaymentModeMutation,
  useGetListpaymentModeAdminQuery,
  useGetPaymentModeListQuery,
} = PaymentModeApi;
