import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './EvolutionChart.css';

const EvolutionChart = ({graph}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedMonth, setSelectedMonth] = useState<number>(() => selectedDate.getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(() => selectedDate.getFullYear());


  useEffect(() => {
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current?.getContext('2d');
    if (!ctx) return;

    const data = {
      labels: [graph?.graphCa?.map(item=>item?.date)],
      datasets: [
        {
          label: 'Chiffre d’affaires',
          data: graph?.graphCa?.map(item=>item?.chiffre_affaire),
          backgroundColor: '#053965',
          borderColor: '#053965',
          borderWidth: 1,
        },
        {
          label: 'Bénéfice',
          data: graph?.graphBenefice?.map(item=>item?.benefice),
          backgroundColor: '#F46425',
          borderColor: '#F46425',
          borderWidth: 1,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      layout: {
        padding: {
          bottom: 30,
        },
      },
    };

    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });

    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  const handleInputClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(e.target.value, 10);
    const newDate = new Date(selectedYear, newMonth, 1);
    setSelectedMonth(newMonth);
    setSelectedDate(newDate);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(e.target.value, 10);
    const newDate = new Date(newYear, selectedMonth, 1);
    setSelectedYear(newYear);
    setSelectedDate(newDate);
  };

  const renderYearOptions = () => {
    const years: JSX.Element[] = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 50; year <= currentYear + 50; year++) {
      years.push(<option key={year} value={year}>{year}</option>);
    }
    return years;
  };
  

  const renderMonthOptions = () => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months.map((month, index) => (
      <option key={month} value={index}>{month}</option>
    ));
  };

  return (
    <div>
      <div className='d-flex align-items-center mt-5'>
        <h2 className='me-3'>Evolution</h2>
        <input 
          type="text" 
          placeholder="Nov-Juil" 
          className="inp" 
          onClick={handleInputClick} 
          value={selectedDate.toLocaleDateString()} 
          readOnly 
        />
        {showDatePicker && (
          <div className="datepicker-container">
            <DatePicker 
              selected={selectedDate} 
              onChange={(date) => setSelectedDate(date as Date)} 
              inline 
            />
            <div className="select-container">
              <select value={selectedYear} onChange={handleYearChange} className="year-select">
                {renderYearOptions()}
              </select>
              <select value={selectedMonth} onChange={handleMonthChange} className="month-select">
                {renderMonthOptions()}
              </select>
            </div>
          </div>
        )}
      </div>
      <canvas ref={chartRef} width="400" height="200"></canvas>
    </div>
  );
};

export default EvolutionChart;
