import React, { useEffect, useState } from "react";
import { TfiMenuAlt } from "react-icons/tfi";
import { NavLink } from "react-router-dom";
import { useGetCategoryListQuery } from "../../../utils/api/category/category.api";
import { Category } from "../../../utils/api/category/category.type";
import { useGetPromoteProductListQuery } from "../../../utils/api/product/product.api";
import { Product } from "../../../utils/api/product/product.type";
import { getImage, QueryUrl, sortByAlphabetical } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";

function CategoriesList() {
  const [product, setProduct] = useState<Product | undefined>();
  const [selected, setSelected] = useState<Category | undefined>();
  const { data = [], isLoading } = useGetCategoryListQuery();
  // const { data: promos = { results: [], nbPages: 0 } } =
  //   useGetPromoteProductListQuery({
  //     category: selected?.id || "",
  //   });

  // useEffect(() => {
  //   if (selected?._id && promos?.results?.length) {
  //     let item = promos?.results?.find(
  //       (el) =>
  //         el?.category?._id === selected?._id ||
  //         selected?.subCategories?.some((v) => v?._id === el?.category?.id)
  //     );

  //     if (item) {
  //       setProduct(item);
  //     } else {
  //       setProduct(undefined);
  //     }
  //   } else {
  //     setProduct(undefined);
  //   }
  // }, [promos, selected]);
  return (
    <div className="dropdown">
      {/* <button
        className="btn nav-link menu-link menu-link-category dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <TfiMenuAlt />
      </button> */}
      <ul
        className="dropdown-menu dropdown-menu-header-category"
        aria-labelledby="dropdownMenuButton1"
      >
        <li>
          <p className="dropdown-item dropdown-item-title">Nos univers</p>
        </li>
        {!isLoading &&
          (data?.length ? (
            sortByAlphabetical(data)?.map((item) => (
              <div className="dropdown" id="dropdown" key={item?._id}>
                <li
                  onMouseOver={() =>
                    setSelected((prevState) => {
                      if (prevState && prevState?.id !== item?._id) {
                        return item;
                      }
                      return item;
                    })
                  }
                  onMouseLeave={() => setSelected(undefined)}
                >
                  <NavLink
                    className="dropdown-item dropdown-item-categorie "
                    to={`/achats/${QueryUrl("", {
                      category: item?.subCategories?.length
                        ? JSON.stringify(
                            item?.subCategories?.map((el) => el?._id)
                          )
                        : "",
                    })}`}
                  >
                    {item?.name}
                  </NavLink>
                  {!!item?.subCategories?.length && (
                    <div className="dropdown-content ">
                      <p className="dropdown-item-title text-uppercase">
                        {item?.name}
                      </p>
                      <p className="sub-category-list text-capitalize">
                        {sortByAlphabetical(item?.subCategories)?.map(
                          (el, i) => (
                            <NavLink
                              to={`/achats/${QueryUrl("", {
                                category: JSON.stringify([el?._id]),
                              })}`}
                              className="no-link"
                            >
                              {" "}
                              {el?.name +
                                `${
                                  i < item?.subCategories?.length - 1
                                    ? ", "
                                    : ""
                                }`}{" "}
                            </NavLink>
                          )
                        )}
                      </p>
                      <div className="">
                        {selected?.id === item?.id && product && (
                          <div className="content-info-category-header">
                            <div className="content-reduction-card">
                              <h3 className="text-title-card-reduction mb-1 text-center">
                                REDUCTION DE
                              </h3>
                              <h2
                                className="title-card-reduction mb-3 text-center"
                                style={{
                                  fontSize: 50,
                                  lineHeight: "75.4%",
                                }}
                              >
                                -25%
                              </h2>
                              <div className="content-img-product-banner">
                                <img
                                  loading="lazy"
                                  src={getImage("/")}
                                  alt="Produit en réduction"
                                  className="img-product-reduction"
                                />
                              </div>
                              <div className="content-btn-card-reduction pt-3 text-center">
                                <NavLink
                                  to={`/produit/${product?.id}`}
                                  state={product}
                                  className="btn btn-card-reduction"
                                >
                                  J’en profite
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              </div>
            ))
          ) : (
            <AlertInfo message="Aucune catégorie disponible pour le moment!" />
          ))}

        {/* <li>
          <a className="dropdown-item dropdown-item-categorie" href="/#">
            EPICERIE SALEE
          </a>
        </li> */}
      </ul>
    </div>
  );
}

export default CategoriesList;
