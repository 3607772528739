import React from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Dashboard from '../DashBoard/Dashboard';
import { Routes, Route, Outlet } from 'react-router-dom';
import Vendeurs from '../Vendeurs/Vendeurs';
import Commandes from '../Commandes/Commandes';
import Produits from '../Produits/Produits';
import Administration from '../Administration/Administration';

const Layout = () => {
  return (
    <div>
      <Header />
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
