import React, { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { AppRoutes } from './routes/router';
import { useAppDispatch, useAppSelector } from './redux/hook';
import { onSetUser } from './redux/slice/User.slice';

function FetchUser() {
  const { user } = useAppSelector((s) => s?.user);

  const dispatch = useAppDispatch();
  if (user) {
    dispatch(onSetUser(user));
  }

  return null;
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}
function App() {
  // const isConnected = useAppSelector((s) => !!s.user?.token);
  const routes = useRoutes(AppRoutes);

  return (
    <div className="app-routing-container">
      <ScrollToTop />
      {/* {isConnected && <FetchUser />} */}
      {routes}
    </div>
  );
}

export default App;
