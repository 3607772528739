import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { MdKeyboardArrowRight, MdFileUpload } from "react-icons/md";
import image from '../../../../assets/image-admin/Image.png';
import './AjouVendeur.css';
import { Input } from "../../../common/Input";
import Select from "react-select/dist/declarations/src/Select";
import UseAddOrUpdateVendeurForm from "./UseAddOrUpdateVendeurForm";
import ErrorMessage from "../../../common/ErrorMessage";
import { Link, NavLink, useParams, useLocation, useNavigate} from 'react-router-dom';


const AjouVendeur = () => {

  const { slug } = useParams<{ slug: string }>();

  let { state } = useLocation();


  const {
    register,
    errors,
    setValue,
    isLoading,
    onSubmit,
    image,
    cardImg,
    handleChangeAvatar
} = UseAddOrUpdateVendeurForm(state);

  return (
    <div className="pt-5">
      <div className="d-flex justify-content-between m-5 items-center ">
        <h4 className='fw-bold title-form-register'>Ajouter un vendeur</h4>
      </div>
      <div className="add-sub-admin-form-wrapper m-4">
          <form onSubmit={onSubmit}>
              <div className="form-content">
                  <div className="row custom-row">
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Prenom"
                              type="text"
                              customClass="modal__input"
                              placeholder="Prenom"
                              id="__first_name"
                              {...register("prenom")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.prenom &&
                                  errors?.prenom?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Nom"
                              type="text"
                              customClass="modal__input"
                              placeholder="Nom"
                              id="__last_name"
                              {...register("nom")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.nom &&
                                  errors?.nom?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Email"
                              type="email"
                              customClass="modal__input"
                              placeholder="Email"
                              id="__email"
                              {...register("email")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.email &&
                                  errors?.email?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <label
                              htmlFor="Type"
                              className={`form-label custom-form-label`}
                              aria-labelledby="status"
                          >
                              Type
                          </label>
                          <select
                              className="form-select custom-form-control mb-2 modal__input"
                              id="status"
                              {...register("user_type")}
                          >
                              <option selected disabled>Choisir</option>
                              <option value={"VENDEUR"}>Vendeur</option>
                          </select>
                          <ErrorMessage
                              message={
                                  errors?.user_type &&
                                  errors?.user_type?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Téléphone"
                              type="tel"
                              required
                              id="telephone"
                              {...register("telephone")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.telephone &&
                                  errors?.telephone?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Nom de la boutique"
                              type="text"
                              required
                              id="nom_de_la_boutique"
                              {...register("nom_de_la_boutique")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.nom_de_la_boutique &&
                                  errors?.nom_de_la_boutique?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Couleur"
                              type="color"
                              required
                              id="couleur"
                              {...register("couleur")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.couleur &&
                                  errors?.couleur?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Domaine"
                              type="text"
                              required
                              id="domaine"
                              {...register("domaine")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.domaine &&
                                  errors?.domaine?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <label
                              htmlFor="Type"
                              className={`form-label custom-form-label`}
                              aria-labelledby="status"
                          >
                              Devise
                          </label>
                          <select
                              className="form-select custom-form-control mb-2 modal__input"
                              id="devise"
                              {...register("devise")}
                          >
                              <option selected disabled>Choisir</option>
                              <option value={"EUR"}>EUR</option>
                              <option value={"USD"}>USD</option>
                              <option value={"XOF"}>XOF</option>
                          </select>
                          <ErrorMessage
                              message={
                                  errors?.devise &&
                                  errors?.devise?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Adresse"
                              type="text"
                              id="adresse"
                              {...register("adresse")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.adresse &&
                                  errors?.adresse?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="wave api key"
                              type="text"
                              id="wave_api_key"
                              {...register("wave_api_key")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.wave_api_key &&
                                  errors?.wave_api_key?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="wave api secret key"
                              type="text"
                              id="wave_api_secret_key"
                              {...register("wave_api_secret_key")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.wave_api_secret_key &&
                                  errors?.wave_api_secret_key?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Stripe publishable key"
                              type="text"
                              id="stripe_publishable_key"
                              {...register("stripe_publishable_key")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.stripe_publishable_key &&
                                  errors?.stripe_publishable_key?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Stripe secret key"
                              type="text"
                              id="stripe_secret_key"
                              {...register("stripe_secret_key")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.stripe_secret_key &&
                                  errors?.stripe_secret_key?.message
                              }
                          />
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3 aptm-wrapper">
                          <Input
                              label="Stripe endpoint secret"
                              type="text"
                              id="stripe_endpoint_secret"
                              {...register("stripe_endpoint_secret")}
                          />
                          <ErrorMessage
                              message={
                                  errors?.stripe_endpoint_secret &&
                                  errors?.stripe_endpoint_secret?.message
                              }
                          />
                      </div>
                      {/* <div className="col-md-12 col-lg-12 mb-3 aptm-wrapper">
                          <Input
                              label="Avatar"
                              type="file"
                              required
                              id="__avatar"
                              accept="image/*"
                              onChange={(e: any) => handleChangeAvatar(e)}
                              //error={errors?.cover_image?.message}
                          />
                          {!!cardImg && (
                              <img
                                  src={cardImg}
                                  alt="card-img"
                                  style={{
                                      width: "25%",
                                      height: "12vw",
                                      objectFit: "fill",
                                  }}
                              />
                          )}
                      </div> */}
                  </div>
              </div>
              <div className="pt-3 btn-wrapper d-flex align-items-center justify-content-end gap-3 flex-wrap pb-3">
                  <button
                      title="Annuler"
                      type="button"
                      className="btn custom_take__btn modal__btn"
                  >
                      Annuler
                  </button>
                  <button
                      title="Ajouté"
                      type="submit"
                      className="btn modal__btn blue__btn"
                      disabled={isLoading}
                  >
                      {!isLoading
                          ? !slug
                              ? "Ajouté"
                              : "Modifié"
                          : "Loading..."}
                  </button>
              </div>
          </form>
      </div>
    </div>
  );
};

export default AjouVendeur;
