import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl, VendeurApikey } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../Utils";
import { Address, ChangePasswordData } from "../auth/auth.type";
import {
  AddressFormData,
  AuthState,
  IUser,
  PaginationResults,
  TypeQuery,
  UserFormData,
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: AuthState }).user.token ??
    AppStorage.getItem<AuthState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  headers.set("api-key", VendeurApikey);
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["user", "userList", "userById", "adminsList", "vendeurByApikey"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    // me: builder.query<IUser, void>({
    //   providesTags: ["user"],
    //   // transformResponse: ({ data }) => data,
    //   query: () => {
    //     return "user/me/";
    //   },
    // }),
    addOrEditUser: builder.mutation<IUser,{ slug?: string; data: UserFormData | FormData }>({
      invalidatesTags: ["user", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `users/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditAdmin: builder.mutation<IUser,{ slug?: string; data: UserFormData | FormData }>({
      invalidatesTags: ["user", "adminsList", "userList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `adminuser/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `adminuser/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrEditUserAdresse: builder.mutation<
      Address,
      { slug?: string; data: AddressFormData }
    >({
      invalidatesTags: ["user", "userList", "adminsList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `user/adresse/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/adresse`,
          method: "POST",
          body: data,
        };
      },
    }),
    changePassword: builder.mutation<any, ChangePasswordData>({
      query: (data) => ({
        url: `auth/update-password/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    getListUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["userList"],
      query: (query) => QueryUrl("user", query),
    }),
    getListAdminUser: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["adminsList"],
      query: (query) => QueryUrl("adminuser", query),
    }),
    userById: builder.query<IUser | any, string>({
      query: (id) => `users/${id}`,
      providesTags: ["userById"],
    }),
    archiveUser: builder.mutation<IUser | any, string>({
      query: (id) => ({
        url: `user/admin/archive/${id}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    unArchiveUser: builder.mutation<IUser | any, string>({
      query: (slug) => ({
        url: `user/admin/unarchive/${slug}/`,
        method: "GET",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    deleteUser: builder.mutation<IUser, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["user", "adminsList", "userList"],
    }),
    vendeurByApikey: builder.query<IUser | any, string>({
      query: () => `vendeur/${VendeurApikey}`,
      providesTags: ["vendeurByApikey"],
    }),
  }),
});

export const {
  //useMeQuery,
  useAddOrEditUserMutation,
  useChangePasswordMutation,
  useAddOrEditUserAdresseMutation,
  useGetListUserQuery,
  useAddOrEditAdminMutation,
  useGetListAdminUserQuery,
  useLazyUserByIdQuery,
  useLazyVendeurByApikeyQuery,
  useArchiveUserMutation,
  useUnArchiveUserMutation,
  useDeleteUserMutation
} = UserApi;

export function useUserFromLocation(): [
  IUser,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyUserByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as IUser, isLoading, id as string, findById];
}
