import React from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';

// import './Profil.css'
const Profil = () => {
  return (
    <Container className="mt-5 pt-5">
    <Form>
      <h5>Paramètres du compte</h5>
        <Col className="mt-5">
          <Form.Group controlId="formBasicNom">
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" placeholder="Entrez votre nom" className="large-input" />
          </Form.Group>
        </Col>
        <Col className="mt-3">
          <Form.Group controlId="formBasicPrenom">
            <Form.Label>Prénom</Form.Label>
            <Form.Control type="text" placeholder="Entrez votre prénom" className="large-input" />
          </Form.Group>
        </Col>

        <Col className="mt-3">
          <Form.Group controlId="formBasicPhone">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control type="text" placeholder="Entrez votre numéro" className="large-input" />
          </Form.Group>
        </Col>
        <Col className="mt-3">
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Entrez votre email" className="large-input" />
          </Form.Group>
        </Col>
      

      <div className="">
        <Button variant="primary" type="submit" className="custom-button mt-5">
          Enregistrer
        </Button>
      </div>

      <h5 className="mt-5">Mot de passe</h5>

      <Col className="mt-3">
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Mot de passe actuel</Form.Label>
            <Form.Control type="password"  className="large-input" />
          </Form.Group>
        </Col>
        <Col className="mt-3">
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Nouveau mot de passe</Form.Label>
            <Form.Control type="password" placeholder="|" className="large-input" />
          </Form.Group>
        </Col>
        <Col className="mt-3">
          <Form.Group controlId="formBasicPassword">
            <Form.Label>confirmer  mot de passe</Form.Label>
            <Form.Control type="password" placeholder="|" className="large-input" />
          </Form.Group>
        </Col>
        <div className="">
        <Button variant="primary" type="submit" className="custom-button mt-5">
          Enregistrer
        </Button>
      </div>
    </Form>
  </Container>
  );
};

export default Profil;
