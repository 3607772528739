import React from 'react'
import './admin.css'


const Ajouteradmin = () => {
    return (
        <div className='pt-5 mt-5'>
            <h4 className='text-dark fw-bold'>Ajouter un admin</h4>
            <form className="py-3 me-5  text-dark fw-bold">
                <div className="row mb-4">
                    <div className="col-md-6">
                        <label className="form-label">Nom</label>
                        <input type="text" className="form-control" id="validationCustom01" required />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Prenom</label>
                        <input type="text" className="form-control" id="validationCustom02" required />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-6">
                        <label className="form-label">Telephone</label>
                        <input type="phone" className="form-control" id="validationCustom03" required />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Email</label>
                        <input type="mail" className="form-control" id="validationCustom03" required />
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end mb-3">
                    <button className="btn btn-form" type="submit">Enregistrer</button>
                </div>
            </form>
        </div>
    )
}

export default Ajouteradmin
