import React, { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Commande from "../../../assets/images/appImages/product1.png";
import { getImage } from "../../../utils/Utils";

const GaleryProduct = ({ pictures }: { pictures: any }) => {
  const [images, setImages] = useState<
    { original: string; thumbnail: string }[]
  >([]);

  useEffect(() => {
    if (pictures && pictures?.length) {
      setImages(
        pictures?.map((item) => {
          return {
            original: getImage(item?.image),
            thumbnail: getImage(item?.image),
          };
        })
      );
    } else {
      setImages([
        {
          original: getImage(""),
          thumbnail: getImage(""),
        },
      ]);
    }
  }, [pictures]);

  return (
    <div className="container-galery-detail-product">
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        thumbnailPosition={"bottom"}
        lazyLoad={true}
      />
    </div>
  );
};

export default GaleryProduct;
export const images = [
  {
    original: Commande,
    thumbnail: Commande,
  },
  {
    original: Commande,
    thumbnail: Commande,
  },
  {
    original: Commande,
    thumbnail: Commande,
  },
];
