import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { BsTrash } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import {
  calculateTax,
  CartItemType,
  decrement,
  getCartCount,
  getTotalAmount,
  increment,
  removeCartItem,
} from "../../../../redux/slice/useCartSlice";
import { formatCurrency, getImage  } from "../../../../utils/Utils";
import PanierItemTable from "./PanierItemTable";
import {
  useAddProductCartMutation,
  useGetCartByUserQuery,
} from "../../../../utils/api/product/product.api";
import { getVendeur } from "../../../HomePage/HomePage";


const PanierPage = () => {
  const Vendeur = getVendeur();

  const { user } = useAppSelector((s) => s?.user);
  const [total, setTotal] = useState<number>(0);
  const { totalAmount } = useAppSelector((state) => state.cart);
  // const { data:carts } = useGetCartByUserQuery(user?.slug);

  // useEffect(()=>{

  //   if(user?.slug)
  //   {
  //     let arrayData : any = carts

  //     let arrayPrix: any = carts?.items?.map((x)=>(x.prix_total))

  //     setTotal(arrayPrix?.reduce((a, b) => parseInt(a) + parseInt(b), 0))
  //   }
  // },[carts]);

  return (
    <>
      <div className="content-title-user-parcours pb-5">
        <h3 className="user-parcours-title">Mon panier</h3>
      </div>
      <div className="row">
        <div className="col-lg-9">
          <div className="cart-items-table-content">
            <PanierItemTable />
          </div>
        </div>
        <div className="col-lg-3 cart-total">
          <div className="cart-total-card">
            <div className="sm-title pb-4">Total du panier</div>
            {/* <div className="sm-value d-flex justify-content-between align-items-center pb-2">
                        <span>Sous-total</span>
                        <span>1000</span>
                    </div> */}
            <div className="separator"></div>
            <div className="sm-value d-flex justify-content-between align-items-center pt-2">
              <span>TOTAL</span>
              <span>{formatCurrency(totalAmount,Vendeur?.devise)}</span>
            </div>
            <div className="btn-container pt-5">
              <NavLink
                to="/valider-commande"
                className="btn parcours-submit-btn"
              >
                Valider mon panier
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanierPage;

export function Counter({
  count,
  setCount,
  produit,
  user,
}: {
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  produit: any;
  user: any;
}) {
  const [addToCart] = useAddProductCartMutation();

  const incrementQuantite = (item) => {
    addToCart({
      quantite: 1,
      produit: item?.id,
      prix: item?.prix,
      variations: item?.variations,
    });
  };

  const decrementQuantite = (item) => {
    addToCart({
      quantite: -1,
      produit: item?.id,
      prix: item?.prix,
      variations: item?.variations,
    });
  };

  const decrement = () => {
    setCount((prevState) => prevState - 1);
  };

  const increment = () => {
    setCount((prevState) => prevState + 1);
  };

  return (
    <div className="btn-wrap">
      <button
        className="btn decrement-btn btn-counter-item"
        disabled={count <= 1}
        onClick={() => decrement}
      >
        −
      </button>
      <div className="num">{count}</div>
      <button
        className="btn increment-btn btn-counter-item"
        onClick={() => increment}
      >
        +
      </button>
    </div>
  );
}

const CartItem = ({ item }: { item: CartItemType }) => {
  const [count, setCount] = React.useState(item?.quantite);
  const { user } = useAppSelector((s) => s?.user);
  const dispatch = useAppDispatch();
  const Vendeur = getVendeur();


  const getCartItems = () => {
    dispatch(getCartCount());
    dispatch(calculateTax());
    dispatch(getTotalAmount());
  };
  React.useEffect(() => {
    if (count > item?.quantite) {
      console.log("inc", count, item?.quantite);
      dispatch(increment(item));
      getCartItems();
    }

    if (count < item?.quantite) {
      console.log("dec", count, item?.quantite);
      dispatch(decrement(item));
      getCartItems();
    }
  }, [count]);

  const onRemoveItem = () => {
    dispatch(removeCartItem(item));
    getCartItems();
  };
  return (
    <div className="detail-commande-item card-panier-detail mb-1">
      <div className="content-info-commande-item w-50">
        <div className="content-img-commande-item">
          <NavLink
            to={`/produit/${item?.produit}`}
            state={item}
            className="no-link "
          >
            <img
              loading="lazy"
              src={getImage("/")}
              alt="Commande"
              className="img-commande-item"
            />
          </NavLink>
        </div>
        <div className="info-commande-item">
          <p className="text-name-commande-item mb-1">{item?.produit?.nom}</p>
          <div className="text-muted" style={{ fontSize: "0.80rem" }}>
            {formatCurrency(item?.prix,Vendeur?.devise)}
          </div>
          <div className="content-rate-product">
            <StarRatingComponent
              name="rate2"
              starCount={5}
              value={item?.prix}
              starColor="#DD9812"
              emptyStarColor="#D4D6D5"
            />
          </div>
        </div>
      </div>
      <div className="content-qty-items w-15">
        <div className="counter ">
          <Counter
            setCount={setCount}
            count={count}
            produit={item}
            user={user}
          />
        </div>
      </div>
      <div className="content-detail-commande-panier">
        <p className="text-price-commande-item">
          {formatCurrency(item?.prix * item?.quantite,Vendeur?.devise)}
        </p>
        <div className="content-btn-panier-page mb-3">
          <button className="btn" onClick={() => onRemoveItem()}>
            <BsTrash style={{ color: "#E20613", fontSize: 20 }} />
          </button>
        </div>
      </div>
    </div>
  );
};
