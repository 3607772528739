import { Address } from "../auth/auth.type";

export enum UserType {
  admin = "ADMIN",
  vendeur = "VENDEUR",
  acheteur = "ACHETEUR",
  superadmin = "superadmin",
}

export type CiviliteType = "homme" | "femme";

export interface IUser {
  id: string;
  slug: string | any;
  userType: string;
  avatar: string | any;
  email: string;
  prenom: string;
  nom: string;
  telephone: string;
  password: string;
  confirmPassword: string;
  adresse: string;
  adresses: Address[];
  birthDate: string | Date;
  deleted: boolean;
  user_type: string;
}

export type UserFormData = Partial<{
  id?: string;
  slug: string | any;
  userType: string;
  avatar: string | any;
  email: string;
  prenom: string;
  nom: string;
  telephone: string;
  password?: string;
  confirmPassword?: string;
  adresse: string;
  adresses?: Address[];
  birthDate?: string | Date;
  user_type: string;
}>;

export interface AuthState {
  user: IUser | any;
  token?: string | null;
  panier?: any | null;
}

export type PaginationResults<T> = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: T[];
  limit?: number;
  nbPage?: number;
  nbPages?: number;
};

export type TypeQuery = Partial<{
  page?: number;
  limit?: number;
  word?: string | null;
  slug?: string;
  name?: string | null;
  minPrice?: string | null;
  maxPrice?: string | null;
  category?: string | null;
  orderBy?: string | null;
  order?: "DESC" | "ASC";
  user?: string;
  product?: string;
  productType?: "pack" | "product";
  numOrder?: string;
  search?: string;
  q?: string;
  userType?: string;
  read?: boolean;
  displayType?: "week" | "month" | "year";
  date?: string;
  zone?: string;
  prix_min?: string | null;
  prix_max?: string | null;
  sous_categorie?: string;
  categorie?: string | null;
}>;

export type AddressFormData = {
  _id: string;
  slug: string | any;
  prenom: string;
  nom: string;
  adresse: string;
  complementAdresse: string;
  quartier: string;
  ville: string;
  telephone1: string;
  telephone2: string;
  isDefault: boolean;
  latitude: number | string;
  longitude: number | string;
  zone: string;
};
