import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { DashboardKpi, Kpi } from "./dashboard.type";

export const DashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["dashboard"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDashboardApp: builder.query<any , void>({
      providesTags: ["dashboard"],
      query: () => {return "dashboard/";},
    }),
    getKpi: builder.query<Kpi, void>({
      query: () => "kpi/",
    }),
  }),
});

export const {useGetDashboardAppQuery,useGetKpiQuery} = DashboardApi